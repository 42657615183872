@font-face {
  font-family: "Volte Regular Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Regular Italic"), url("../fonts/Volte Regular Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte";
  font-style: normal;
  font-weight: normal;
  src: local("Volte"), url("../fonts/Volte.woff") format("woff");
}
@font-face {
  font-family: "Volte Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light Italic"), url("../fonts/Volte Light Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Light";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Light"), url("../fonts/Volte Light.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium Italic"), url("../fonts/Volte Medium Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Medium"), url("../fonts/Volte Medium.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold Italic"), url("../fonts/Volte Semibold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Semibold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Semibold"), url("../fonts/Volte Semibold.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold Italic"), url("../fonts/Volte Bold Italic.woff") format("woff");
}
@font-face {
  font-family: "Volte Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Volte Bold"), url("../fonts/Volte Bold.woff") format("woff");
}
body {
  height: 100%;
  background-color: #f6f6f4;
  font-family: "Volte";
  color: #000000;
}

h1 {
  font-family: "Volte Bold";
  margin-top: 0px;
  margin-bottom: 0;
  font-size: 38px;
  line-height: 1em;
}

h2 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: "Volte";
  font-size: 16px;
  line-height: 1em;
  font-weight: 400;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: "Volte";
  font-size: 20px;
  line-height: 1em;
  font-weight: 700;
}

h5 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #909090;
  font-size: 16px;
  line-height: 1em;
  font-weight: 400;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
}

p {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 1.4em;
}

a {
  text-decoration: underline;
  color: #e07400;
}
a:hover {
  color: #e07400;
}

button {
  border: none;
}

[type=text],
[type=password] {
  width: 100%;
  border-style: none;
  border-radius: 5px;
  background-color: #f3f3f3;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  height: 38px;
  border: 1px solid #d9d9d9;
}

[type=text]:focus,
[type=password]:focus,
[type=number]:focus {
  outline: 0;
}

select {
  height: 38px;
  width: 100%;
  margin-bottom: 10px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  transition: border 300ms ease;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #949494;
  line-height: 1em;
  font-weight: 400;
}

.alert-text {
  color: #d52424;
}

.full-screen {
  margin-top: 360px;
}

.button.close {
  width: 125px;
  float: right;
  margin-right: 6px;
  text-align: center;
  background: #000000;
}

.form-field {
  border-radius: 5px;
  background-color: #f3f3f3;
  border: 1px solid #d7d7d7;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.form-field--white {
  background-color: #ffffff;
}

.form-field--pointer {
  cursor: pointer;
}

.form-field:focus {
  border: none;
  background-color: #d7d7d7;
  outline: none;
}

.info-icon {
  padding: 10px;
  position: relative;
  cursor: pointer;
  top: -2px;
}

.__react_component_tooltip {
  pointer-events: auto !important;
}

.tooltip-text {
  margin: 0;
  font-size: 14px;
  min-width: 140px;
  max-width: 200px;
  line-height: 15px;
  white-space: normal;
  text-align: left;
}
.tooltip-text--right {
  min-width: 240px;
}
.tooltip-text--preview {
  min-width: 120px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbb;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1500;
  text-align: center;
}

.modal-container__backdrop {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  z-index: 10000;
  background-color: #000;
}

.clyde-modal-content {
  position: absolute;
  top: 25%;
  width: 100%;
}

.modal-centered {
  width: 50%;
  margin: auto;
}

.row.gutterless {
  margin: 0;
  padding: 0;
}

.clyde-select div.Select-control {
  background-color: #d7d7d7;
  border-radius: 5px;
  font-size: 14px;
  max-width: 300px;
  width: 80%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.Select-value {
  line-height: 15px;
}

.Select-value-icon {
  padding: 5px 5px 1px;
}

.Select-placeholder {
  line-height: 37px !important;
}

.Select-arrow-zone {
  padding-top: 3px;
}

.Select--multi .Select-value {
  margin-top: 7px !important;
}

.Select-clear {
  line-height: 2 !important;
}

.clyde-select.customer div.Select-control,
.clyde-select.customer div.Select-menu-outer {
  font-size: 14px;
  text-align: left;
}

.clyde-select.customer.is-focused:not(.is-open) > .Select-control {
  border-color: #d7d7d7 !important;
  background-color: #f3f3f3 !important;
  box-shadow: none;
}

.clyde-select.customer,
.clyde-select.customer div.Select-control {
  background-color: #f3f3f3;
  border-color: #d7d7d7;
  width: 100%;
  height: 38px;
  max-width: none;
}

.clyde-select div.Select-control:focus,
.clyde-select:focus {
  outline: none;
}

.gradient {
  min-height: 100vh;
  background-image: url("/images/Clyde_background.png");
  background-color: #fff;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: fixed;
  background-origin: content-box;
}

.page-layout__content.center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.title-wrapper__prefixed-subtitle {
  margin-bottom: 10px;
}

.fixed-save-bar {
  position: static;
  left: 0px;
  width: 80%;
  bottom: 59px;
  display: block;
  z-index: 300;
  margin-right: auto;
  margin-left: auto;
}

.process-div {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80px;
  z-index: 20;
  margin-left: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  background-color: #fafafa;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}

.page-container {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  display: block;
  margin-top: 0px;
  min-height: 740px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-layout {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  margin-top: 0px;
  margin-left: 50px;
  padding-top: 70px;
  padding-bottom: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-layout__content {
  position: relative;
  width: 80%;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-wrapper {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.title-wrapper {
  display: block;
  width: 80%;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button-no-style,
.button-nostyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-rendering: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
  text-indent: inherit;
  text-shadow: inherit;
  text-align: inherit;
  align-items: inherit;
  overflow: visible !important;
}

.button-internal {
  width: 100%;
  height: 100%;
}

.button-fakelink {
  color: #e07400;
  text-decoration: underline;
}

.button-fakelink:hover,
.button-fakelink:focus {
  outline: 0;
  color: #e07400;
}

.overflow-off {
  overflow: hidden;
}

.button--small {
  width: 147px !important;
}

@media (max-width: 767px) {
  .menu-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    padding-right: 14px;
    padding-left: 14px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .icon {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #fafafa;
  }
}
.fake-label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  line-height: 1em;
  font-weight: 400;
  cursor: default;
}

.fake-label--black {
  color: black;
}

.s-alert-box,
.s-alert-top,
.s-alert-is-effect,
.s-alert-effect-stackslide,
.s-alert-show {
  z-index: 2147483647 !important;
}

.settings {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
}

.settings__title {
  margin-top: 30px;
}

.settings__header {
  height: 36px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 2px solid #e7e7e7;
  margin: 0 0 40px 0;
}

.settings-tab-header-button {
  font-family: "Volte Semibold";
  color: #d1d1d1;
  text-decoration: none;
  margin: 0 20px;
}
.settings-tab-header-button:first-child {
  margin: 0 20px 0 0;
}
.settings-tab-header-button:last-child {
  margin: 0 0 0 20px;
}
.settings-tab-header-button:focus {
  outline: none;
  color: #727272;
  text-decoration: none;
}
.settings-tab-header-button:hover {
  color: #727272;
  text-decoration: none;
}

.settings-tab-header-button--active {
  position: relative;
  color: #000000;
}
.settings-tab-header-button--active:after {
  height: 2px;
  width: 103%;
  content: "";
  position: absolute;
  bottom: -7px;
  left: -1px;
  background: #000000;
  cursor: default;
  pointer-events: none;
}

.settings-tab {
  width: 100%;
}

.settings-tile {
  width: 100%;
  margin: auto;
  background: #ffffff;
}

.settings-tile__title {
  font-weight: bold;
  font-size: 22px;
  border: 2px solid #d7d7d7;
  border-radius: 5px 5px 0 0;
  margin-bottom: -2px;
  padding: 15px;
  letter-spacing: -0.46px;
}

.settings-tile__content {
  padding: 15px;
  border: 2px solid #d7d7d7;
  border-top: 0;
  border-radius: 0 0 5px 5px;
  min-height: 150px;
}

.settings-tile__content__subtitle {
  width: 100%;
  margin-top: 30px;
  padding-left: 15px;
  text-decoration: underline;
}

.settings-tile__content__subtitle:first-of-type {
  margin-top: 0;
}

.settings-tile__content__subtitle:last-of-type {
  margin-top: 30px;
}

.subtitle--no-padding {
  padding-left: 0;
}

.subtitle--claims-contact {
  padding-left: 0;
  margin-bottom: -30px;
}

.settings-tile__content__loading-container {
  position: relative;
  top: 40px;
}

.action-complete {
  position: relative;
  top: 30%;
  z-index: 100;
  margin: auto;
  border-radius: 5px;
  user-select: none;
}

.action-complete__check-svg {
  max-width: 150px;
  max-height: 150px;
  width: 100px;
  margin: auto;
  display: block;
}

.action-complete__text {
  margin: 30px auto;
  text-align: center;
  font-size: 20px;
  color: #000000;
}

.action-complete__check-svg__path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.action-complete__check-svg__path--circle {
  -webkit-animation: dash 0.9s ease-in-out;
  animation: dash 0.9s ease-in-out;
}

.action-complete__check-svg__path--check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
  animation: dash-check 0.9s 0.35s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.affirm-integration-icon {
  height: 100px;
  width: 100px;
}

.clyde-integration-logo {
  height: 100px;
  width: 100px;
}

.connect-integration-icon {
  margin-right: 15px;
  margin-left: 15px;
}

.connect-integration-btn {
  background: linear-gradient(149.7deg, #000000 -112.28%, #414141 98.83%);
  border-radius: 8px;
  padding: 12px 24px;
  color: white;
}

.affirm-integration-modal {
  height: 488px !important;
  width: 600px !important;
}

.cancel-integration-btn {
  border: 2px solid #d1d1d1;
  border-radius: 8px;
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  width: 97px;
  height: 50px;
  background-color: white;
  color: #196fe7;
  margin-left: 8px;
}

#affirm-integration-modal {
  height: 400px;
}

.affirm-integration-modal-body {
  margin-top: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.logo-integration-section {
  margin-bottom: 24px;
}

.affirm-integration-modal input {
  background-color: white;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin: 0px 8px;
  width: 194px;
  height: 50px;
}

.affirm-integration-modal-body h2 {
  font-family: Volte;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  text-align: center;
  color: #000000;
}

.affirm-integration-modal-body p {
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
}

.affirm-integration-input-section {
  display: flex;
  margin-bottom: 56px;
}

.affirm-integration-btn-section button:hover {
  cursor: pointer;
}

.affirm-integration-setting-section {
  align-self: flex-start;
}

.bank-account {
  min-height: 32px;
  margin: 5px 0 10px 0;
}

.bank-account__content {
  align-items: center;
}

.stripe-microdeposit-input {
  margin: 0;
  padding: 0;
  padding-left: 10px;
}

.stripe-verify-button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: none;
  background: #000000;
  border-radius: 5px;
  width: auto;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.stripe-verify-button--tooltip {
  width: 100%;
}

.bank-verification-warning {
  color: #d52424;
  width: 100%;
  padding-left: 15px;
}

.deposit-locked-warning {
  width: 100%;
  padding-left: 15px;
  color: #d52424;
}

.clyde-tooltip-container {
  width: auto !important;
  height: auto !important;
}

.stripe-verify-button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}

.stripe-verify-button:disabled {
  cursor: not-allowed;
  background-color: #909090;
}

.stripe-verify-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
}

.stripe-verify-button--error {
  background: #f15a57;
  cursor: default;
}

.stripe-verify-button--error:hover {
  box-shadow: none;
}

.stripe-default-account-text {
  text-align: center;
  color: #000000;
}

.set-stripe-default-button {
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  transition: all 200ms ease;
  user-select: none;
}

.set-stripe-default-button:hover {
  text-decoration: underline;
}

.set-stripe-default-button:active {
  text-decoration: none;
}

.modal-container__backdrop--billing {
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 1;
}

.billing-instructions {
  position: absolute;
  top: 16%;
  width: 60%;
  left: 20%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #000000;
  line-height: 22px;
  padding: 50px 60px 30px 60px;
  user-select: none;
  font-size: 15px;
}

.billing-instructions__title {
  font-size: 38px;
  font-weight: bold;
  line-height: 38px;
  margin-top: 10px;
}

.billing-instructions__section {
  margin-top: 24px;
}

.billing-instructions__section__bold-text {
  font-weight: bold;
}

.billing-instructions__button-container {
  margin-top: 40px;
  text-align: center;
}

.billing-instructions-more-questions {
  margin: 20px auto;
  display: block;
  cursor: pointer;
  color: #000000;
}

.billing-instructions-confirm {
  margin: auto;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: none;
  display: block;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.billing-instructions-confirm:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.billing-instructions-confirm:hover {
  background: #414141;
  box-shadow: -2px 2px 8px 0px #b3b3b3;
}

.billing-options {
  padding: 10px 40px 20px 40px;
}

.billing-options__back {
  color: #949494;
  display: inline-block;
  cursor: pointer;
}

.billing-options__back__image {
  width: 20px;
}

.billing-options__row {
  align-items: center;
}

.billing-options__row__option {
  width: 45%;
  min-width: 45%;
  font-size: 18px;
  text-align: center;
}

.billing-option__bold-text {
  font-weight: bold;
}

.billing-option__button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 18px;
  cursor: pointer;
  float: none;
  background: #000000;
  border-radius: 5px;
  width: 90%;
  line-height: 54px;
  height: 54px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  margin: 10px auto;
}
.billing-option__button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.billing-option__button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
  background: #414141;
}

.billing-option__sub-text {
  margin-top: 10px;
}

.billing-options__row__separator {
  margin-bottom: 10px;
  width: 10%;
  min-width: 10%;
  text-align: center;
  font-size: 38px;
  color: #000000;
  letter-spacing: -1.06px;
}

.settings-tile--billing {
  margin-bottom: 55px;
}
#setup-billing-component .settings-tile--billing {
  max-width: 80%;
}

.billing-lock-icon {
  margin: 0 10px 0 3px;
  position: relative;
  top: -2px;
}

.add-billing-button {
  -webkit-transition: all 350ms ease;
  position: relative;
  top: -39px;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.add-billing-button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.add-billing-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
  background: #414141;
}

.settings-tile__content--invoice {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bank-accounts__header__item {
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 0;
}

.settings-tile__title__info-icon {
  display: inline;
  cursor: pointer;
  position: relative;
  top: 6px;
  left: 5px;
}

.catalogue-modal {
  position: relative;
}

.catalogue-modal__search {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 25px;
}

.catalogue-modal-search__form {
  width: 100%;
  margin-bottom: 5px;
}

.catalogue-modal-search__form__text {
  width: 100%;
  margin: 0;
}

.catalogue-modal-search__pagination {
  margin: 0;
}

.platform-catalogue-item-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px #d7d7d7;
}

.catalogue-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px #d7d7d7;
}

.platform-catalogue-item-wrapper > .catalogue-item {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.catalogue-item-variants {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.catalogue-item-variants li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.catalogue-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.catalogue-item-info {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  margin-left: 10px;
}

.catalogue-item__left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.catalogue-item__check {
  margin-right: 10px;
}

.catalogue-item-img {
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.catalogue-item-info__name,
.catalogue-item-info__sku {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.catalogue-item-info__name {
  font-family: Volte Semibold;
}

.catalogue-item-info__sku {
  color: #4a4a4a;
}

.catalogue-item__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a4a4a;
}

.catalogue-modal-bottom {
  position: sticky;
  bottom: 1px;
  height: 55px;
  width: 100%;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  justify-items: center;
  align-items: center;
  background-color: white;
  border-top: solid 1px #d7d7d7;
}

.catalogue-modal__button {
  grid-column-start: 2;
  width: 160px;
  color: white;
  background-color: #000000;
  border-radius: 5px;
  padding: 5px 0;
  cursor: pointer;
}
.catalogue-modal__button:focus, .catalogue-modal__button:hover {
  outline: 0;
  background-color: #414141;
}

.catalogue-modal__count {
  grid-column-start: 3;
  justify-self: end;
  font-size: 14px;
  margin-right: 25px;
}

@media (min-width: 768px) {
  .catalogue-item-variants {
    width: 90%;
    margin: 0 0 0 10%;
  }
  .catalogue-item-variants li {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .catalogue-item__left {
    width: 70%;
    flex-direction: row;
    text-align: left;
  }
  .catalogue-item__check {
    margin-right: 10px;
  }
  .catalogue-item__right {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .catalogue-modal__search {
    flex-direction: row;
    justify-content: space-between;
  }
  .catalogue-modal-search__form {
    margin: 0 10px 0 0;
  }
}
.claims__back {
  align-self: flex-start;
  margin-left: 100px;
}

.claims-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.claims-detail h3 {
  font-family: "Volte Bold";
  letter-spacing: 1px;
  margin: 0 0 10px 0;
}
.claims-detail .small-modal-background {
  z-index: 1001;
}

.claims-detail__back-button {
  display: flex;
  align-self: flex-start;
}
.claims-detail__back-button .clyde-back-button__circle {
  margin-left: 20px;
}

.claims-detail-header {
  width: 80%;
  margin: 30px 0 20px 0;
}
.claims-detail-header h2 {
  display: inline;
  font-family: "Volte Medium";
  font-size: 30px;
}

.claims-detail-header-container {
  position: relative;
}
.claims-detail-header-container .limited-warranty-pill {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 9px;
  background: #e8f0fd;
  color: #000000;
  border-radius: 8px;
}

.claims-detail-header__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 20px;
}

.claims-detail-header__info__state {
  font-family: "Volte Medium";
  color: #ff4500;
}

.xclaim-warning {
  font-family: "Volte Medium";
  color: #ff4500;
}

.claims-detail-content {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.claims-detail-content__column {
  min-height: 900px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.claims-detail-content__column:last-child {
  margin-bottom: 0;
}

.claims-detail-card,
.claim-history-card {
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 20px;
  flex-grow: 1;
}
.claims-detail-card:last-child,
.claim-history-card:last-child {
  margin-bottom: 0;
}

.claims-detail-card--claim {
  min-height: 390px;
  flex-grow: 0;
}

.claims-detail-card__loading {
  margin-top: 75px;
}

.claims-detail-card__group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
}
.claims-detail-card__group:last-child {
  margin-bottom: 0;
}

.claims-detail-card__group__title,
.claims-detail-card-event__title {
  display: block;
  font-size: 11px;
  line-height: 13px;
}

.claims-detail-card__depot__title,
.claims-detail-card__depot__title--bold {
  font-size: 16px;
  line-height: 125%;
}

.claims-detail-card__depot__title--bold {
  font-weight: bold;
}

.m-bottom-36 {
  margin-bottom: 36px;
}

.m-bottom-16 {
  margin-bottom: 16px;
}

.claims-detail-card__group__info {
  word-wrap: break-word;
}

.claims-detail-card__group__info--small-text {
  font-size: 14px;
}

.claims-detail-card__group__file-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 3px;
}

.claims-detail-card__group__file-list__loading {
  float: left;
  margin-top: 10px;
}

.claims-file-button {
  height: 34px;
  width: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #000000;
  background-color: white;
  border: 2px solid #000000;
  border-radius: 8px;
  margin: 0 15px 5px 0;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}
.claims-file-button:last-child {
  margin-right: 0;
}
.claims-file-button:hover, .claims-file-button:focus {
  outline: 0;
  font-family: "Volte Medium";
  color: white;
  background-color: #000000;
}

.claims-file-button--small {
  height: 30px;
  width: 60px;
  font-size: 10px;
  padding: 4px 6px;
}

.claim-detail-supporting-file-modal img {
  max-width: 100%;
}

.claim-history-card {
  position: relative;
}

.claim-history-card__event {
  color: #000000;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
}
.claim-history-card__event:last-child {
  margin-bottom: 0;
}

.claim-history-card__dot {
  background: #000000;
  height: 13px;
  width: 13px;
  border-radius: 50%;
  margin-right: 25px;
  z-index: 2;
}

.claim-history-card__line {
  width: 1px;
  position: absolute;
  top: 83px;
  left: 30px;
  background-color: #000000;
}

.claim-detail-resolution-loading {
  margin: 20px 0 30px 0;
}

.claim-detail-resolution {
  width: 80%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 24px;
}

.claim-detail-resolution-input--cost {
  position: relative;
}
.claim-detail-resolution-input--cost .claim-detail-resolution-input {
  padding-left: 26px;
}
.claim-detail-resolution-input--cost .claim-detail-resolution-input-prefix {
  position: absolute;
  top: 12px;
  left: 17px;
}

.depot-repair-cost-field {
  position: relative;
}
.depot-repair-cost-field--sign {
  position: absolute;
  top: 28px;
  left: 8px;
}
.depot-repair-cost-field--sign__cost {
  position: absolute;
  top: 52px;
  left: 8px;
}

.claim-detail-resolution-check {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.claim-detail-resolution__depot-shipping {
  display: flex;
  margin-bottom: 24px;
}
.claim-detail-resolution__depot-shipping > div {
  width: 50%;
}
.claim-detail-resolution__depot-shipping > div:first-child {
  margin-right: 16px;
}

.depot-repair-sublabel {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
}

.depot-repair-description-count {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
}

.depot-resolution-summary {
  display: flex;
  flex-direction: column;
  padding: 24px 30px;
  background: #f6f6f6;
  border-radius: 8px;
}
.depot-resolution-summary--header {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 9px;
  color: #000000;
}
.depot-resolution-summary--info {
  font-size: 11px;
  line-height: 13px;
}

.depot-repair-files-input {
  height: 38px;
}

.depot-repair-main-heading {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.depot-repair-badge {
  padding: 5px 9px;
  background: #e8f0fd;
  color: #000000;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}

.claim-detail-depot-replacement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 100%;
  color: #000000;
  padding: 12px 24px;
  margin-top: 24px;
  border-radius: 8px;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #000000, #414141);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 1px 1000px 1px #fff inset;
}

.claim-detail-replacement {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 100%;
  color: #000000;
  padding: 12px 24px;
  margin-top: 4px;
  border-radius: 8px;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(101deg, #000000, #414141);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 1px 1000px 1px #fff inset;
}

.claim-detail-resolution--modal {
  width: 100%;
  border: none;
  padding: 24px 0;
}

.claim-detail-resolution__group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.claim-detail-resolution__group label {
  color: black;
}

.claim-detail-resolution__group--row {
  flex-direction: row;
}
.claim-detail-resolution__group--row label {
  margin: 0 0 0 5px;
}

.claim-detail-resolution__group__selector {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.claim-detail-selector {
  height: 38px;
  width: 100%;
  font-size: 14px;
  color: black;
  line-height: 34px;
  text-align: center;
  background-color: white;
  border: 1px solid #d1d1d1;
  cursor: pointer;
  user-select: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.claim-detail-selector:focus,
.claim-detail-selector:hover {
  outline: 0;
  text-decoration: underline;
}

.claim-detail-selector:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.claim-detail-selector:nth-child(2) {
  border-top: 0;
  border-bottom: 0;
}

.claim-detail-selector:last-of-type {
  border-bottom: 1px solid #d1d1d1;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.claim-detail-selector--selected {
  color: white;
  background-color: #000000;
  border: 1px solid #3a5998 !important;
  box-shadow: inset 0 0 12px #555;
}

.claim-detail-selector--disabled {
  background-color: #f0f0f0;
  pointer-events: none;
}

.claim-detail-selector--selected-disabled {
  color: white;
  background-color: #909090;
  box-shadow: inset 0 0 12px #555;
  pointer-events: none;
}

.claim-detail-resolution__group__detail {
  font-size: 12px;
}

.claim-detail-resolution__group__warning {
  color: red;
  font-size: 14px;
}

.claim-detail-resolution-input {
  margin: 5px 0;
}

.claim-detail-resolution-upload {
  margin-bottom: 5px;
}

.claim-detail-resolution__line {
  height: 1px;
  width: 100%;
  background-color: #d7d7d7;
  margin-bottom: 15px;
}

.claim-resolution-upload-uploaded-files {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}
.claim-resolution-upload-uploaded-files span {
  font-size: 12px;
}

.claim-detail-resolution-submit {
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.claim-detail-resolution-submit--modal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: none;
  padding: 0;
}
.claim-detail-resolution-submit--modal > button {
  margin: 0px 8px 0px 8px;
}

.claim-detail-resolution-submit .claim-detail-resolution-submit__button,
.claim-detail-resolution-submit .claim-detail-resolution-submit__button {
  margin-bottom: 20px;
}
.claim-detail-resolution-submit .claim-detail-resolution-submit__button:last-child,
.claim-detail-resolution-submit .claim-detail-resolution-submit__button:last-child {
  margin-bottom: 0;
}
.claim-detail-resolution-submit .claim-detail-replacement {
  margin-top: 0;
  border-radius: 5px;
}
.claim-detail-resolution-submit .claim-detail-replacement,
.claim-detail-resolution-submit .claim-detail-resolution-resolve-later__button {
  color: #000000;
  background-color: #ffffff;
}

.claim-detail-resolution-resolve-later__button {
  margin-left: 20px;
}

.claim-detail-depot-resolution {
  width: 100%;
  max-width: 80%;
  display: flex;
  flex-direction: column;
}
.claim-detail-depot-resolution--modal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: none;
  padding: 0 24px;
}
.claim-detail-depot-resolution__button {
  margin-bottom: 20px;
}
.claim-detail-depot-resolution__button:last-child {
  margin-bottom: 0;
}

.claim-review-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Volte;
  font-style: normal;
  color: #000000;
  text-align: center;
}
.claim-review-container img {
  height: 124px;
  width: 124px;
}
.claim-review-container__header {
  font-weight: bold;
  font-size: 36px;
  line-height: 43px;
  margin: 32px 0px;
}
.claim-review-container__text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin: 32px 0px;
}

.claim-review-container .claim-review-container__button {
  width: 162px;
  height: 44px;
}

.resolution-pending-container {
  display: flex;
}

.resolution-pending-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

@media (min-width: 993px) {
  .claims-detail-content {
    flex-direction: row;
    justify-content: space-between;
  }
  .claims-detail-content__column {
    width: 48%;
    margin-bottom: 0;
  }
  .claim-detail-resolution-input {
    max-width: 50%;
  }
  .claim-detail-resolution__group__selector {
    flex-direction: row;
  }
  .resolution-parts-input {
    max-width: 50% !important;
  }
  .claim-detail-resolution-upload {
    max-width: 50%;
  }
  .claim-detail-selector:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 5px;
  }
  .claim-detail-selector:nth-child(2) {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    border-right: 0;
    border-left: 0;
  }
  .claim-detail-selector:last-of-type {
    border-right: 1px solid #d1d1d1;
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0px;
  }
}
.resolution-parts-input .clyde-select-multiple__options-list {
  padding-inline-start: unset;
  margin-bottom: unset;
}

.resolution-parts-input .clyde-select-multiple-option__text {
  width: 100%;
}

.resolution-parts-input .clyde-select-multiple-option:focus {
  background-color: #fff;
  font-family: "Volte";
  color: #000000;
}

.resolution-parts-input .clyde-select-multiple-option--selected:focus {
  background-color: #000000;
  color: #fff;
}

.resolution-parts-input__option {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.part-cost,
.part-name,
.part-model {
  margin-right: 5px;
  width: 125px;
}

.claims-summary {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 50px;
  padding: 50px 10%;
  overflow: scroll;
}

.claims-summary__table {
  width: 100%;
  padding: 0;
}

.claims-summary__table__body {
  background: #ffffff;
  border: 1px solid #D1D1D1;
  border-radius: 8px;
}
.claims-summary__table__body tr {
  cursor: pointer;
  border-radius: 8px;
}
.claims-summary__table__body tr:hover {
  background-color: #f3f3f3;
}

.claims-summary__table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #D1D1D1;
}

.claims-summary__table tr:last-of-type {
  border-bottom: none;
}

.claims-summary__table th,
.claims-summary__table td {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 13px 16px;
}

.claims-summary__table th {
  font-family: "Volte Bold";
  padding: 13px 16px;
}

.claim-summary__table__dot {
  display: inline-block;
  height: 9px;
  width: 9px;
  flex-shrink: 0;
  padding: 0;
  margin-right: 10px;
  border-radius: 50%;
  background-color: transparent;
}

.claims-summary__table__row--new .claim-summary__table__dot {
  background: #D52424;
}
.claims-summary__table__row--new td:nth-of-type(5) {
  color: #D52424;
}

.claims-summary__table__row--pending .claim-summary__table__dot {
  background: #F9D505;
}

tr.claims-summary__table__row--empty {
  padding: 10px;
  border: 0;
  color: #909090;
  cursor: default;
}

.claims-summary__table-info {
  margin: 10px 0 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.claims-summary__table-info .pagination {
  margin-top: 25px;
  margin-bottom: 0;
  margin-left: auto;
}

.claims-summary__table-info__scopes {
  display: flex;
}
.claims-summary__table-info__scopes h4 {
  font-family: "Volte Bold";
  font-size: 16px;
  margin: 5px 0;
}

.claims-summary__scopes__search,
.claims-summary__scopes__filter {
  margin-right: 10px;
}

.claims-summary__scopes__search .clyde-select,
.claims-summary__scopes__filter .clyde-select,
.claims-summary__scopes__sort .clyde-select {
  height: 38px;
  border-radius: 8px;
}

.claims-summary__scopes__search__input {
  border-radius: 8px;
}

.claims-summary__scopes__search {
  min-width: 90px;
}

.claims-summary .clyde-select-single__options-list {
  width: auto;
  top: 0;
  left: 0;
  min-width: 100%;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 10;
  padding-left: 0;
}

.clyde-checkbox-input-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.clyde-checkbox-input {
  transition: opacity 200ms;
  opacity: 0.14;
  width: 15px;
  min-width: 15px;
  height: 15px;
  border-radius: 5px;
  background-color: #000000;
  display: inline-block;
}

.clyde-checkbox-input--selected {
  opacity: 1;
}

.clyde-checkbox-input-text {
  display: inline-block;
  margin-left: 10px;
  font-size: 16px;
}

.clyde-checkbox-input-subtext {
  display: inline-block;
  margin-left: 25px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
  line-height: 14px;
}

.clyde-color-select-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
}

.clyde-color-select {
  display: inline-block;
  margin-top: -10px;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #000000;
  cursor: pointer;
  transition: box-shadow 100ms ease;
}

.clyde-color-select:hover {
  box-shadow: 1px 1px 1px 0px #b3b3b3;
}

.clyde-color-select-input {
  height: 30px;
  width: 100%;
  margin-right: -15px;
  margin-left: 10px;
}

.clyde-color-select-input--invalid {
  background-color: #f15a57;
}

.launch-modal {
  font-size: 22px;
  letter-spacing: -0.2px;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 40px 30px 40px 30px;
  width: 40%;
  margin: auto;
}

.launch-modal-text {
  font-weight: bold;
}

.launch-logos {
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.launch-logos__image {
  width: 70px;
  max-width: 100%;
}

.confirm-launch-button {
  width: 100%;
  border-radius: 5px;
  background: #000000;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.38px;
  font-weight: bold;
  line-height: 50px;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: box-shadow 200ms ease;
}
.confirm-launch-button:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  background: #414141;
}
.confirm-launch-button:active {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.cancel-launch-button {
  border-radius: 5px;
  color: #000000;
  font-size: 18px;
  letter-spacing: -0.38px;
  cursor: pointer;
}

.launch-started-text {
  margin-top: 10px;
  font-size: 18px;
  color: #000000;
  text-align: center;
}

.shopify-permissions {
  margin-top: 30px;
  font-size: 16px;
  padding-left: 0;
  padding-right: 0;
  letter-spacing: -0.38px;
}

.permissions-expl div {
  padding-left: 0;
  padding-right: 0;
}

.shopify-permissions__header {
  font-size: 20px;
  margin-bottom: 5px;
  letter-spacing: -0.5px;
}

.shopify-permissions__separator {
  border: 1px solid #D7D7D7;
  margin: 15px 0;
}

.permission-name {
  font-weight: bold;
}

.sale-item {
  position: relative;
  top: 0;
  transition: top 400ms ease;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.sale-container {
  position: relative;
}

.sale-item--expanded {
  border-color: #000000;
  border-radius: 4px;
  border-bottom: 1px solid #000000;
  margin-bottom: 10px;
  margin-top: 0px;
  top: 4px;
}

.sale-item__content {
  cursor: pointer;
  line-height: 20px;
  min-height: 40px;
  font-size: 14px;
  margin: 0 auto;
  transition: background 400ms ease;
  align-items: center;
  text-align: center;
}

.sale-item__content--expanded {
  color: #ffffff;
  background: #000000;
  border-radius: 4px 4px 0 0;
  background-clip: margin-box;
}

.sale-item:nth-child(2) {
  margin-top: -1px;
}

.sale-item:nth-child(even) {
  background: #f3f3f3;
}

.sale-item:nth-child(odd) {
  background: #ffffff;
}

.sale-item:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.sale-item__details {
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0 0 4px 4px;
  background: #ffffff;
}

.sale-item__details--collapsed {
  border-top: none;
}

.sale-item__details > div {
  padding: 10px;
}

.sale-details__header {
  font-family: "Volte Semibold";
  text-align: left;
  margin-bottom: 5px;
}

.sale-details__content__column {
  align-content: start;
}

.sale-contract-link {
  color: #e07400;
  cursor: pointer;
  text-decoration: underline;
}

.actions-menu-expand {
  user-select: none;
  padding: 5px 5px;
  width: 140px;
  text-align: center;
  border: 1px solid #000000;
  border-radius: 3px;
  color: #000000;
  cursor: pointer;
}

.actions-menu {
  user-select: none;
  cursor: pointer;
  text-align: center;
  width: 140px;
  position: absolute;
  top: 0;
  left: 160px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #f3f3f3;
  color: #949494;
  display: none;
}

.actions-menu--expanded {
  display: block;
}

.actions-menu__item {
  color: #000000;
  padding: 5px 5px;
}

.actions-menu__item:hover {
  background: #d7d7d7;
}

.autopilot-option {
  padding-bottom: 30px;
}

.autopilot-option__title {
  font-weight: bold;
  font-size: 24px;
  display: inline-block;
}

.autopilot-option__title--inactive {
  color: #909090;
}

.autopilot-switch--always-on {
  width: 100px;
  height: 50px;
  float: right;
  background-color: #000000;
  line-height: 50px;
  text-align: center;
  color: #fbfbfb;
  border-radius: 5px;
  box-shadow: inset 0 0 12px #555;
}

.autopilot-option__subtitle {
  font-size: 16px;
  line-height: 29px;
  width: 80%;
}

.autopilot-option__subtitle--inactive {
  color: #909090;
}

.autopilot-switch-container {
  display: inline-block;
  position: absolute;
  right: 0;
}

.autopilot-save-button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  margin-top: 20px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.autopilot-save-button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.autopilot-save-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
}

.products-page-link {
  color: #909090;
  margin: 20px;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  background: none;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
}
.products-page-link:active {
  outline: none;
}
.products-page-link:hover {
  background: none;
  text-decoration: none;
}

.finished-onboarding-modal-content {
  top: 25%;
  width: 90%;
  left: 5%;
}

.finished-onboarding {
  font-size: 22px;
  letter-spacing: -0.2px;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 40px 30px 40px 30px;
  width: 50%;
  margin: auto;
}

.finished-onboarding__header {
  font-weight: bold;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.finished-onboarding__text {
  font-size: 20px;
  line-height: 24px;
  margin: 20px 0;
}

.finished-onboarding__button,
.launch-button {
  width: 100%;
  border-radius: 5px;
  background: #000000;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.38px;
  font-weight: bold;
  line-height: 50px;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  -webkit-transition: -webkit-box-shadow 200ms ease;
  transition: -webkit-box-shadow 200ms ease;
  -o-transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease;
  transition: box-shadow 200ms ease, -webkit-box-shadow 200ms ease;
}

.launch-button:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.launch-button:active {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.margin-options {
  display: inline-block;
  width: 70%;
}

.margin-options__recommended {
  font-style: italic;
  font-size: 16px;
  padding-top: 5px;
}

.margin-options__amount-input {
  width: 90%;
  height: 50px;
  text-align: right;
  padding-right: 50px;
  padding-left: 50px;
  font-size: 20px;
  background: #f3f3f3;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}
.margin-options__amount-input:focus {
  outline: 0;
}

.margin-options__percent {
  position: relative;
  left: -40px;
  font-size: 18px;
}

.margin-options__round-total {
  text-align: left;
  margin: 10px 0 10px 0;
}

.margin-explanation em {
  color: #e07400;
  font-weight: bold;
}

.cop-button-small {
  height: 25px;
  width: 25px;
  display: inline-block;
  border: solid 2px #000000;
  border-radius: 5px;
  transition: transform 0.3s ease, background-color 0.5s ease;
  cursor: pointer;
}

.cop-button-small:focus,
.cop-button-small:hover {
  outline: 0;
  background-color: #000000;
}

.cop-button-small:focus .cop-button-small__arrow,
.cop-button-small:hover .cop-button-small__arrow {
  border-left: 6px solid white;
}

.cop-button-small.delivery-date {
  transform: scale(0.7);
  margin-left: 3px;
}

.cop-button-small__arrow {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 6px solid #000000;
  transition: transform 0.3s ease;
}

.cop-button-small--closed {
  background-color: transparent;
}

.cop-button-small--open {
  background-color: #000000;
}

.cop-button-small__arrow--open {
  border-left: 6px solid white;
  transform: rotate(90deg);
}

.expired-contract-details-button {
  border: 2px solid #5c5c5c;
}

.expired-contract-details-button.cop-button-small__arrow {
  border-left: 6px solid #5c5c5c;
}

.expired-contract-details-button > .cop-button-small__arrow--closed {
  border-left: 6px solid #5c5c5c;
}

.active-contract-details-button--open > .cop-button-small__arrow--closed,
.expired-contract-details-button--open > .cop-button-small__arrow--closed {
  border-left: 6px solid white;
}

.expired-contract-details-button:focus,
.expired-contract-details-button:hover {
  background-color: #5c5c5c;
}

.active-contract-details-button--open:focus,
.active-contract-details-button--open:hover,
.expired-contract-details-button--open:focus,
.expired-contract-details-button--open:hover {
  background-color: transparent;
}

.active-contract-details-button--open {
  border: 2px solid white;
  background-color: transparent;
  transition: background-color 0s;
  display: hidden;
}

.expired-contract-details-button--open {
  border: 2px solid white;
  background-color: #5c5c5c;
  transition: background-color 0s;
  display: hidden;
}

.active-contract-details-button--open.cop-button-small__arrow,
.expired-contract-details-button--open.cop-button-small__arrow {
  background-color: transparent;
  border-left: 6px solid white;
}

.cop-button-small--open.expired-contract-details-button {
  background-color: #5c5c5c;
}

.cop-button-big {
  height: 37px;
  width: 132px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 15px;
  background: #000000;
  border-radius: 5px;
}
.cop-button-big:focus, .cop-button-big:hover {
  outline: 0;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
}
.cop-button-big:hover {
  background: #414141;
}

.cop-button-big__arrow {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.cop-button-big__arrow--open {
  border-left: 6px solid white;
  transform: rotate(90deg);
}

.cop-modal-background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}

.cop-modal-background-light {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(74, 74, 74, 0.35);
  z-index: 10000;
}

.cop-modal-foreground {
  height: 90%;
  width: 75%;
  position: absolute;
  text-align: left;
  background: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  padding: 80px 100px;
  overflow: auto;
}

.cop-modal-header__cancel {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  float: right;
  color: #909090;
  font-size: 30px;
  line-height: 30px;
}
.cop-modal-header__cancel span {
  font-size: 20px;
  margin-right: 20px;
}

.cop-small-modal-foreground {
  height: 65%;
  width: 60%;
  margin: auto;
  text-align: left;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.cop-small-modal-header {
  position: sticky;
  top: 0;
  width: 100%;
  padding: 25px 25px 20px 25px;
  background-color: white;
  border-bottom: 1px solid #d7d7d7;
  z-index: 10000;
}

.cop-small-modal-line {
  height: 1px;
  width: 100%;
  background-color: #d7d7d7;
}

.cop-small-modal-line--half-size {
  width: 50%;
}

.cop-small-modal-header__header {
  font-family: Volte Semibold;
  margin: 0;
}

.cop-small-modal-header__cancel {
  cursor: pointer;
  float: right;
  color: #000000;
  font-size: 15px;
  line-height: 15px;
}

.cop-small-modal-content {
  margin: 25px;
}

.catalogue-modal__search {
  margin: 20px 25px;
}

.catalogue-modal__search__text {
  margin: 0;
  width: 100%;
}

.cop-small-modal-header__cancel:focus,
.cop-small-modal-header__cancel:hover,
.cop-modal-header__cancel:focus,
.cop-modal-header__cancel:hover {
  outline: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.cop-modal-header__explanation {
  text-align: left;
  margin: 0 0 10px 0;
}

.cop-modal-header__explanation--product-name {
  margin: 15px 0 5px 0;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cop-modal-content {
  margin-top: 20px;
}

.cop-modal-foreground .cop-modal-actions__confirm {
  max-width: 400px;
  box-shadow: none;
  margin: auto;
  float: none;
  margin-bottom: 10px;
}

.cop-modal-foreground__action-complete {
  padding-top: 80px;
}

.cop-resend-modal__contact,
.cop-cancel-modal__contact {
  min-width: 200px;
  width: 75%;
  margin: 30px auto;
  text-align: left;
}

.cop-resend-modal__label,
.cop-cancel-modal__label {
  margin-right: 15px;
  display: inline-block;
}

.cop-resend-modal__label--invalid,
.cop-cancel-modal__label--invalid {
  color: #f15a57;
}

.cop-resend-modal__input,
.cop-cancel-modal__input {
  display: inline-block;
}

.cop-file-claim__input__inline {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.cop-file-claim__input__inline:last-child {
  margin-bottom: 0;
}
.cop-file-claim__input__inline label {
  margin: 0;
}

.cop-modal-actions {
  text-align: center;
}

.cop-modal-actions--resend,
.cop-modal-actions--cancel {
  min-width: 200px;
  width: 50%;
}

.cop-file-claim__content {
  display: flex;
  flex-direction: column;
}

.cop-file-claim__form {
  width: 75%;
  max-width: 500px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  margin-top: 32px;
}

.cop-file-claim__input {
  margin-bottom: 20px;
}

.cop-file-claim__label {
  color: #909090;
}

.cop-file-claim__radio-label {
  color: black;
}

.cop-file-claim__label,
.cop-file-claim__input__upload-errors,
.cop-file-claim__input__info {
  display: block;
}

.cop-file-claim__input__upload-errors,
.cop-file-claim__input__info {
  margin: 5px 0 0 0;
}

.cop-file-claim__label--invalid,
.cop-file-claim__input__info--invalid,
.cop-file-claim__input__upload-errors,
.cop-file-claim__narrative-char-count--max {
  color: #f15a57;
}

.cop-file-claim__narrative-char-count {
  font-size: 13px;
}

.cop-file-claim__input__label-narrative {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.cop-file-claim-text,
.cop-file-claim-date,
.cop-file-claim-textarea {
  margin: 0;
}

.cop-file-claim-date,
.cop-file-claim-textarea {
  outline: 0;
  width: 100%;
  font-size: 14px;
  background-color: #f3f3f3;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 8px 12px;
}

.cop-file-claim-date {
  height: 38px;
  transition: all 200ms ease;
}

.cop-file-claim-textarea {
  height: 60px;
}

.cop-file-claim__input__radio {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.cop-file-claim__input__radio input {
  margin-right: 10px;
}
.cop-file-claim__input__radio label {
  color: black;
  margin: 0;
}

.cop-file-claim__dropdown {
  height: 35px;
  width: 100%;
  margin: 0;
  font-size: 15px;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.cop-file-claim__input__radio ~ .cop-file-claim__input__radio {
  margin-bottom: 0;
}

.cop-file-claim__input__upload {
  display: flex;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.cop-file-claim-upload {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  position: relative;
  font-size: 15px;
  text-align: left;
  margin: 0;
  padding: 10px;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
}

.cop-file-claim-upload__input:hover ~ .cop-file-claim-upload__content,
.cop-file-claim-upload__input:focus ~ .cop-file-claim-upload__content,
.cop-file-claim-upload:hover > .cop-file-claim-upload__content--label {
  text-decoration: underline;
}

.cop-file-claim-upload__img {
  height: 20px;
  width: 20px;
  color: #000000;
  margin: 0 20px 0 10px;
}

.cop-file-claim-upload__content--label {
  font-size: 15px;
}

.cop-file-claim-upload__content--files {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.cop-file-claim-upload [type=file] {
  cursor: inherit;
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
}

.cop-file-claim-submit {
  margin-top: 30px;
}

.cop-modal-foreground--cop-file-claim .cop-file-claim__limited-notification.cop-file-claim__limited-notification--purple {
  color: #000000;
}
.cop-modal-foreground--cop-file-claim .cop-file-claim__limited-notification.cop-file-claim__limited-notification--purple .clyde-notification-message-type.clyde-notification-message-type--alert {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 13.5V7' stroke='rgb(128,36,228)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Ccircle cx='12' cy='17' r='1' fill='rgb(128,36,228)'/%3E%3Cpath d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z' stroke='rgb(128,36,228)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.cop-modal-foreground--update-claim {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.cop-claim,
.cop-claim-history {
  width: 75%;
  max-width: 500px;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
}

.cop-modal--loading {
  padding-top: 100px;
}

.cop-claim__claim-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.cop-claim__claim-info__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.cop-claim__claim-info__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cop-claim-history__history-list {
  margin-top: 20px;
}

.cop-claim-history__history-list__event {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 0 25px 0;
  margin: 25px 0 0 0;
}
.cop-claim-history__history-list__event:first-child {
  margin: 0;
}
.cop-claim-history__history-list__event:last-child {
  border-bottom: none;
}

.cop-claim-history__history-list__event--no-history {
  border-bottom: none;
}

.cop-claim-history__history-list__event--no-history span {
  font-family: Volte Semibold;
}

.cop-claim-history__history-list__event__group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.cop-claim-history__history-list__event__group span:last-child {
  font-family: "Volte Medium";
}

.history-list__event__bottom__status {
  font-family: Volte Semibold;
}

.cop-modal-foreground--cop-bulk-sale {
  display: flex;
  flex-direction: column;
}

.cop-bulk-sale__content {
  color: #4a4a4a;
}

.cop-bulk-sale__content__terms {
  display: flex;
  justify-content: flex-end;
}

.cop-bulk-sale__terms-header {
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px 0 15px;
  white-space: nowrap;
}

.cop-bulk-sale__terms-header:last-child {
  margin: 0 0 0 15px;
}

.cop-bulk-sale__terms-header button {
  font-size: 11px;
  text-decoration: underline;
}

.cop-bulk-sale__terms-header button:focus,
.cop-bulk-sale__terms-header button:hover {
  outline: 0;
  color: #000000;
}

.cop-bulk-sale-cart {
  display: flex;
  justify-content: space-between;
}

.cop-bulk-sale-cart__left {
  display: flex;
  align-items: center;
  width: 50%;
  margin-bottom: 10px;
}

.cop-bulk-sale-cart__left img {
  min-width: 50px;
  width: 50px;
  margin-right: 15px;
}

.cop-bulk-sale-cart__left__text {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cop-bulk-sale-cart__left__text__top {
  font-family: Volte Semibold;
  font-size: 12px;
  color: #000000;
}

.cop-bulk-sale-cart__left__text__bottom {
  font-family: Volte Semibold;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cop-bulk-sale-cart__right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cop-bulk-sale-radio-group {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cop-bulk-sale-radio-group__column {
  width: 50px;
  margin: 0 15px 0 15px;
  display: flex;
  justify-content: center;
}

.clyde-radio-input,
.cop-bulk-sale-radio-group__column__radio {
  outline: 0;
  display: none;
  -webkit-appearance: none;
  height: 17px;
  min-width: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #979797;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.clyde-radio-input:focus,
.clyde-radio-input:hover,
.cop-bulk-sale-radio-group__column__radio:focus,
.cop-bulk-sale-radio-group__column__radio:hover {
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
}

.clyde-radio-input:checked,
.cop-bulk-sale-radio-group__column__radio:checked {
  background: #000000;
  border: 1px solid #000000;
  box-shadow: inset 0 0 0 3px #fff;
}

.clyde-radio-input:checked:focus,
.clyde-radio-input:checked:hover,
.cop-bulk-sale-radio-group__column__radio:checked:focus,
.cop-bulk-sale-radio-group__column__radio:checked:hover {
  background: #000000;
  border: 1px solid #000000;
  box-shadow: inset 0 0 0 3px #fff, 0 0 7px 0 rgba(0, 0, 0, 0.2);
}

.cop-bulk-sale-radio-group__column:last-child {
  margin: 0 0 0 15px;
}

.cop-bulk-sale-radio-group__column--fake {
  font-family: Volte Semibold Italic;
}

.cop-bulk-sale-checkout {
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: flex-end;
  margin-top: 50px;
}

.cop-bulk-sale-checkout__info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Volte Semibold Italic;
  margin-bottom: 10px;
}

.cop-bulk-sale-checkout__info label {
  color: #000000;
  text-align: left;
  margin: 0;
}

.cop-bulk-sale-checkout__info input {
  height: 30px;
  width: 40px;
  font-family: Volte Semibold Italic;
  background-color: white;
  border-radius: 0;
}

.cop-bulk-sale-checkout__info input:focus {
  background-color: white;
}

.cop-bulk-sale-checkout__info--error {
  font-size: 14px;
  color: red;
}

.cop-bulk-sale-checkout__info__percent-sign {
  width: 60px;
  border: 1px solid #979797;
}
.cop-bulk-sale-checkout__info__percent-sign input {
  border-style: none;
  padding: 6px 5px;
  overflow: visible;
  margin: 0;
  font-size: inherit;
  line-height: inherit;
}

.cop-bulk-sale-checkout__info__percent-sign.cop-bulk-sale-checkout__info__percent-sign--error {
  color: red;
  border: 1px solid red;
}

.cop-bulk-sale-checkout__info__input--error {
  color: red;
}

.cop-bulk-sale-checkout__line {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 5px 0 15px 0;
}

.cop-bulk-sale-checkout__check {
  display: flex;
  align-items: center;
  margin: 30px 0 20px 0;
}

.cop-bulk-sale-checkout__check__input--disabled {
  opacity: 0.7;
  cursor: default;
}

.cop-bulk-sale-checkout__check__input--disabled:focus,
.cop-bulk-sale-checkout__check__input--disabled:hover {
  box-shadow: none;
}

.cop-bulk-sale-checkout__check__label {
  color: #000000;
  text-align: left;
  margin: 0;
}

.cop-bulk-sale-checkout__check__label--disabled {
  color: #909090;
}

.claims-feedback {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.claims-feedback__img {
  height: 100px;
  width: 100px;
  margin-bottom: 40px;
}

.claims-feedback__header {
  font-family: "Volte Bold";
  font-size: 30px;
  letter-spacing: 1px;
  margin: 0 0 30px 0;
}

.claims-feedback__copy {
  width: 80%;
  text-align: center;
  margin-bottom: 50px;
}

.claims-feedback__button {
  width: 60% !important;
}

.claims-feedback__link {
  display: flex;
  height: 38px;
  padding: 0 24px;
  color: #ffffff;
  background: linear-gradient(149.7deg, #000000 -112.28%, #414141 98.83%);
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none;
}
.claims-feedback__link:hover {
  color: #ffffff;
  text-decoration: none;
}

.claims-feedback .claims-feedback__copy-button {
  margin-top: 24px;
  text-decoration: underline;
}

.cop-modal-foreground--cop-file-claim .cop-file-claim__limited-notification {
  width: 100%;
}

.cop-modal-foreground--hi-clyde-referral {
  bottom: 5%;
}

.claims-feedback__copy-button__input {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: inherit;
  background: inherit;
  outline: none;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.cop-change-order-customer .cop-modal-header {
  margin-bottom: 30px;
  padding: 0 10px;
}
.cop-change-order-customer .cop-modal-actions {
  width: 100%;
  margin-top: 50px;
}

.cop-change-order-customer__contact-form {
  padding: 0 10px;
}

.cop-change-order-customer__verifications {
  list-style-type: none;
  margin: 0;
  padding: 15px 10px 0;
}

.cop-change-order-customer__verifications li {
  display: flex;
  margin-bottom: 10px;
}

.cop-change-order-customer__verifications label {
  vertical-align: text-top;
  margin: 0 0 0 10px;
}

.cop-change-order-customer__error {
  color: red;
  text-align: center;
  margin-top: 17px;
  margin-bottom: -39px;
}

@media (min-width: 1200px) {
  .cop-small-modal-content {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;
  }
}
/*CUSTOMER SEARCH*/
.customers {
  min-width: 800px;
  display: flex;
  flex-direction: column;
}

.customers__header__section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.customers__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 5px;
}
.customers__header h1 {
  margin-bottom: 8px;
}

.customers__header__buttons {
  display: flex;
  flex-direction: row;
}

.customers__header__actions {
  display: none;
  height: 37px;
  width: 132px;
  color: white;
  font-size: 15px;
  background: #000000;
  border-radius: 5px;
}

.customers__input__form {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.customers__input {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.customers__input h2 {
  margin-bottom: 8px;
  font-weight: 500;
}

.arrow-right {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  margin-left: 10px;
}

.customers__input__form > .customers__input__form__search-type {
  height: 48px;
  width: 200px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.customers__input__form > .customers__input__form__search {
  height: 48px;
  width: 400px;
  font-size: 16px;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  padding: 12px 16px 12px 42px;
}
.customers__input__form > .customers__input__form__search:hover {
  background-color: #eeeeee;
}
.customers__input__form > .customers__input__form__search:hover.clyde-text-input--search {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L16.65 16.65' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}

.customers__list__search-results {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  align-content: center;
  margin: 24px;
}
.customers__list__search-results h1 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Volte";
  margin-bottom: 8px;
  margin-top: 16px;
}
.customers__list__search-results svg {
  margin: auto;
}

.clyde-text-input--search.customers__input__form__search {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L16.65 16.65' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 4% 45%;
}

#customers__header__button-registrations {
  background-color: #fff;
  border: 1.5px solid #000;
  color: #000;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18.9999 13V17C18.9999 17.5304 18.7892 18.0391 18.4141 18.4142C18.039 18.7893 17.5303 19 16.9999 19H2.99988C2.46944 19 1.96074 18.7893 1.58566 18.4142C1.21059 18.0391 0.999878 17.5304 0.999878 17V13M4.99988 8L9.99988 13M9.99988 13L14.9999 8M9.99988 13V1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-position: 10% 40%;
  margin-right: 8px;
  padding: 12px 24px 12px 64px;
}

button#customers__header__button-registrations:hover {
  background-color: #eeeeee;
}

.customers__list {
  width: 100%;
  font-size: 15px;
}

/*Table equivalents*/
.customers__list__table-header,
.customers__list__table-body {
  min-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.customers__list__table-header {
  font-family: Volte Semibold;
}

.customers__list__table-body {
  color: #4a4a4a;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.customers__list__table-body--loading {
  justify-content: center;
}

/*Row equivalents*/
.customers__list__table-header__row,
.customers__list__table-body__row {
  display: flex;
  flex-direction: row;
}

.customers__list__table-header__row {
  padding: 0 25px;
}

.customers__list__table-body__row {
  border-bottom: solid 1px #d7d7d7;
  padding: 15px 25px;
}

.customers__list__table-body__row:last-child {
  border-bottom: none;
}

.customers__list__table-body__row:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.customers__list__table-body__row:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.customers__list__table-body__row:focus,
.customers__list__table-body__row:hover {
  outline: 0;
  background-color: #eeeeee;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.01, 1.05);
  transition: box-shadow 0.2s ease, background-color 0.2s ease, transform 0.2s ease;
  cursor: pointer;
  z-index: 100;
  border-bottom: solid 1px #eeeeee;
  border-radius: 5px;
}

/*Cell equivalents*/
.customers__list__table-header__row__cell-left,
.customers__list__table-body__row__cell-left {
  min-width: 300px;
  width: 50%;
}

.customers__list__table-header__row__cell-center,
.customers__list__table-body__row__cell-center {
  min-width: 150px;
  width: 30%;
}

.customers__list__table-header__row__cell-right,
.customers__list__table-body__row__cell-right {
  min-width: 150px;
  width: 20%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.customers__list__table-body__row__cell-left {
  font-family: Volte Medium;
}

.hidden-link {
  display: none;
}

.customize-content {
  width: 80%;
  margin: auto;
  margin-bottom: 70px;
}

.customize-settings.col-right {
  max-width: 600px;
  padding-left: 2em;
}

.customize-tile {
  margin-top: 1em;
}

.customize-tile:first-child {
  margin-top: 0;
}

.customize-tile__header {
  border-bottom: 1px solid #d9d9d9;
  font-family: "Volte Semibold";
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

.customize-tile__header__info-icon {
  float: right;
  position: relative;
  bottom: 25px;
  right: 5px;
  cursor: pointer;
}

.customize-tile__content {
  margin-bottom: 20px;
}

.customize-option {
  margin: 15px 0 10px 0;
}

.customize-option__settings {
  margin-left: 25px;
}

.customize-option__title {
  align-items: center;
  display: flex;
  height: 100%;
  min-height: 24px;
}
.customize-option__title input[type=radio] {
  height: 16px;
  width: 16px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 50%;
  border: 2px solid #949494;
}
.customize-option__title input[type=radio]:checked {
  box-shadow: inset 0 0 0 3px white !important;
  background-color: #000;
  border: 2px solid #000000;
}

.customize-option__title__label {
  margin-left: 25px;
  display: inline-block;
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}

.customize-option__title__label--small {
  font-size: 14px;
}

.customize-option__description {
  margin-left: 25px;
  font-size: 14px;
}

.customize-option--type {
  margin-top: 5px;
  margin-bottom: 8px;
}

.customize-option--type--api {
  margin-top: 20px;
  margin-bottom: 8px;
}

.customize-option__subtitle {
  font-size: 14px;
}

.customize-option__select {
  background-color: #f3f3f3;
  border-radius: 5px;
  font-size: 14px;
  max-width: 300px;
  width: 80%;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 5px;
  padding-left: 15px;
  background: url(/images/select-arrows.svg) 98% no-repeat #eee;
}

.custom-location-input {
  display: inline-block;
  max-width: 250px;
  height: 30px;
  margin-bottom: 0;
  margin-left: 5px;
}

.custom-location-link {
  display: inline-block;
  cursor: pointer;
}

.custom-location-link__icon {
  position: relative;
  top: -2px;
  margin-left: 5px;
  display: inline-block;
}

.customize-option-desc {
  margin-left: 25px;
  font-size: 14px;
}

.customize-post-purchase-desc {
  font-size: 14px;
}

.customize-option--post-purchase {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clyde-checkbox-label {
  color: #000000;
  margin-top: 6px;
}

iframe.cta-preview {
  display: block;
  width: 100%;
  height: 320px;
  border: 0;
  background: none;
}

.settings-tile__title--developers {
  border-bottom: none;
}

.settings-tile__content--developers {
  min-height: 50px;
}

.security-info-icon {
  cursor: pointer;
  display: inline;
  position: relative;
  top: -1px;
}

.security-settings-save-container {
  width: 100%;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tile-loading-container {
  position: relative;
  top: 40px;
}

.settings-tile__content__section {
  margin-top: 6px;
}

.api-keys-section {
  align-items: center;
  margin-top: 4px;
}

.settings-tile__content__section__link {
  display: block;
  box-sizing: border-box;
}

.settings-tile__content--live {
  min-height: 50px;
  border-radius: 4px 4px 5px 5px;
  border: 2px solid rgba(25, 95, 231, 0.5);
}

.settings-tile__content--test {
  min-height: 50px;
  border-radius: 4px 4px 5px 5px;
  border: 2px solid rgba(5, 195, 121, 0.5);
}

.api-keys-section__key-name {
  margin: 0px 0px 2px 0px;
}

.api-keys-section__key-value {
  display: flex;
  align-items: center;
}

@media (max-width: 576px) {
  .api-keys-section__key-value__text {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 14px;
  }
}
.show-secret-button {
  display: inline-block;
  padding: 0;
  border-radius: 5px;
  width: 210px;
  cursor: pointer;
  text-align: center;
  user-select: none;
  border: 1px solid #ddd;
}

@media (max-width: 576px) {
  .show-secret-button {
    width: 100%;
  }
}
.show-secret-button:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
}

.show-secret-button:active {
  box-shadow: none;
}

.api-security__label {
  display: flex;
  flex-direction: row;
  margin: 19px 0px 0px 0px;
}

.api-security__tooltip-container {
  display: inline;
  position: relative;
  top: -8px;
  left: 0;
  cursor: pointer;
}

.clyde-dropdown-button {
  position: relative;
}

.clyde-dropdown-button__menu {
  width: 350px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 44px;
  right: 0;
  z-index: 10000;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.clyde-dropdown-button__menu li {
  height: 50px;
  width: 100%;
}

.clyde-dropdown-button__menu__button {
  height: 100%;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
}

.clyde-dropdown-button__menu__button:focus,
.clyde-dropdown-button__menu__button:hover {
  outline: 0;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.clyde-dropdown-button__arrow {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid white;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.clyde-dropdown-button__arrow--open {
  border-left: 6px solid white;
  transform: rotate(90deg);
}

.sale-customer-label {
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  padding-left: 0;
  text-align: left;
  min-height: 1.5em;
  display: flex;
  align-items: center;
}

.sale-customer-label--invalid {
  color: #f15a57;
}

.sale-customer-field-error {
  color: #f15a57;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sale-checkbox-container {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
}

.sale-checkbox {
  background: #fbfbfb;
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  color: #fff;
  width: 28px;
  height: 28px;
  padding: 2px 6px;
  cursor: pointer;
  font-size: 20px;
  transition: all 200ms ease;
  margin-top: 2px;
}

.sale-checkbox--checked {
  background: #000000;
  border: none;
}

.sale-checkbox--checked {
  background: #000000;
  border: none;
}

.sale-checkbox-text {
  cursor: pointer;
  line-height: 32px;
  margin: 0 10px;
}

.sale-checkbox-text {
  font-size: 16px;
}

.finish-sale {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  background: #000000;
  border-radius: 5px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  margin-top: 20px;
  border: 1px solid #000000;
  float: none;
  width: 100%;
}

.finish-sale:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}

.finish-sale:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
}

.finish-sale--disabled {
  border: 1px solid #000000;
  color: #000000;
  background-color: #f3f3f3;
}

.button--hidden {
  width: 0px;
  opacity: 0;
  cursor: default;
  letter-spacing: -8px;
}

.s-alert-box {
  padding: 10px 60px;
}

.s-alert-close {
  margin-right: 60px;
  margin-top: 6px;
}

.clyde-modal-content--export-modal {
  height: 100%;
  top: 12%;
}

.export-modal-content {
  min-height: 300px;
  width: 70%;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: left;
  padding: 50px 60px 40px 50px;
}

.export-modal-content__cancel-export {
  cursor: pointer;
  float: right;
  color: #909090;
  font-size: 30px;
  line-height: 30px;
}

.export-modal-content__calendar--invisible {
  display: none;
}

.export-modal-content__calendar--visible {
  display: block;
  background: #f3f3f3;
  position: absolute;
  z-index: 150000;
  top: 20%;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.export-modal-content__dates {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.export-modal-content__dates__group {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.export-modal-content__dates__group-inputs {
  width: 100%;
  text-align: center;
  font-size: 16px;
  margin: 0 0 15px 0;
  cursor: pointer;
}

.export-modal-content__submit-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.export-modal-content__submit-button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  background: #000000;
  border-radius: 5px;
  width: 60% !important;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}

.integration-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 274px;
  height: 386px;
  background: #ffffff;
  border-radius: 6px;
}

.integration-tile__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 275px;
  margin: 24px 0 0 0;
  padding: 0 24px;
}

.integration-tile__header__menu-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  width: 30px;
  height: 100%;
}
.integration-tile__header__menu-button:focus {
  outline: 0;
}
.integration-tile__header__menu-button:hover {
  cursor: pointer;
}
.integration-tile__header__menu-button:focus > div, .integration-tile__header__menu-button:hover > div {
  background: #196fe7;
}

.integration-tile__header__menu-button--open div {
  background: #196fe7;
}

.integration-tile__header__menu-button__ellipse {
  height: 6px;
  width: 6px;
  background: #d1d1d1;
  border-radius: 6px;
}

.integration-tile__header__toggle {
  background-color: #d1d1d1;
  border: 1px solid #d1d1d1;
  border-radius: 32px;
}

.integration-tile__header__toggle--active {
  background-color: #196fe7;
}

.integration-tile__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
  flex-grow: 1;
  width: 275px;
}

.integration-tile__body__logo {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 50%;
  margin: 30px 0 0;
}

.integration-outside-logo {
  height: 70px;
  width: 70px;
}

.integration-tile__body__header {
  font-family: Volte;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 160%;
  text-transform: capitalize;
}

.integration-tile__body__text {
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px 0;
}
.integration-tile__body__text p {
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 160%;
  text-align: center;
  margin: 0;
}

.integration-tile__body__button {
  height: 34px;
  width: 125px;
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
  line-height: 160%;
  background: linear-gradient(149.7deg, #000000 -112.28%, #414141 98.83%);
  border-radius: 8px;
}
.integration-tile__body__button:focus, .integration-tile__body__button:hover {
  cursor: pointer;
}

.remove-integration-btn {
  margin-top: 5px;
  height: 40px;
  width: 170px;
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #ffffff;
  line-height: 160%;
  background: linear-gradient(149.7deg, #000000 -112.28%, #414141 98.83%);
  border-radius: 8px;
}
.remove-integration-btn:focus, .remove-integration-btn:hover {
  cursor: pointer;
}

.integration-tile__body__button--DISABLED {
  background: linear-gradient(149.7deg, #000000 -112.28%, #414141 98.83%);
  color: #ffffff;
}

.integration-tile__body__button--ENABLED {
  background: #e7fff3;
  color: #00a851;
}
.integration-tile__body__button--ENABLED:focus, .integration-tile__body__button--ENABLED:hover {
  pointer-events: none;
}

.integration-tile__body__button--ERROR {
  background: #fcf0f0;
  color: #d52424;
}
.integration-tile__body__button--ERROR:focus, .integration-tile__body__button--ERROR:hover {
  background: hsl(0, 67%, 86%);
}

.integration-tile__body__button--NEEDS_CONFIG {
  background: #fff7e2;
  color: #e1a60e;
}
.integration-tile__body__button--NEEDS_CONFIG:focus, .integration-tile__body__button--NEEDS_CONFIG:hover {
  background: hsl(43, 100%, 84%);
}

.integration-tile__body__button--PROCESSING {
  background: #f7f0fd;
  color: #000000;
}
.integration-tile__body__button--PROCESSING:focus, .integration-tile__body__button--PROCESSING:hover {
  background: hsl(272, 76%, 87%);
}

.integration-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
  left: 15px;
  z-index: 2;
  background-color: #ffffff;
}

.integration-menu__option {
  color: #000;
  cursor: pointer;
  font-family: Volte;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  padding: 12px 16px;
  line-height: 13px;
  text-decoration: none;
}
.integration-menu__option:first-child {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.integration-menu__option:last-child {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.integration-menu__option:focus, .integration-menu__option:hover {
  outline: 0;
  background-color: #e8f0fd;
  text-decoration: none;
}

.logo-upload-area {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  align-items: center;
}

.logo-container {
  padding-left: 0;
  text-align: center;
}

.logo-container__thumb {
  max-width: 100%;
  border-radius: 5px;
  max-height: 90px;
  border-radius: 5px;
}

.logo-settings-section {
  min-height: 100px;
  align-items: center;
}

.logo-upload-container {
  padding-right: 0;
  text-align: center;
}

.logo-upload-container__button {
  width: 250px;
  max-width: 250px;
  margin: auto;
  background: #000000;
  border-radius: 5px;
  padding: 10px 5px;
  cursor: pointer;
  text-align: center;
  color: #ffffff;
  display: inline-block;
}
.logo-upload-container__button:hover {
  box-shadow: 1px 1px 1px 0px #d9d9d9;
  background: #414141;
}
.logo-upload-container__button:active {
  box-shadow: none;
}

.logo-upload-container__error-text {
  color: #f15a57;
  word-wrap: break-word;
}

.logo-upload-input-container {
  display: none;
}

.import-summary-tile {
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  overflow-y: scroll;
  max-height: 170px;
  transition: height 300ms ease;
}

.import-summary-tile--collapsed {
  overflow-y: scroll;
}

.import-summary-tile__count {
  margin: 10px auto 0 auto;
  font-size: 45px;
  line-height: 60px;
  font-weight: bold;
}

.import-summary-tile__count--blue {
  color: #000000;
}

.import-summary-tile__count--red {
  color: #f15a57;
}

.import-summary-tile__count--yellow {
  color: #e07400;
}

.import-summary-tile__subtitle {
  font-weight: bold;
  font-size: 16px;
}

.import-summary__product-list {
  text-align: left;
  padding-left: 30px;
}

.import-summary__product-list__item {
  font-size: 14px;
}

.import-summary__error-list {
  padding-left: 3px;
}

.integration-settings__header-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.clyde-text-input.integration-settings__search-input {
  margin-top: 10px;
  width: 250px;
  height: 50px;
  border: 1px solid #d1d1d1;
  box-sizing: border-box;
  border-radius: 8px;
}

.integration-settings-body {
  display: flex;
}

.integration-settings-body__result {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(274px, 1fr));
  gap: 40px 20px;
}

.integration-settings-body__no-result {
  justify-content: center;
  display: flex;
  text-align: center;
  flex-direction: column;
}

.integration-settings-body__filter-option-section {
  min-width: 170px;
  max-width: 170px;
  margin-right: 56px;
}

.integration-settings-body__filter-option {
  text-align: left;
  padding-left: 16px;
  color: #949494;
  width: 100%;
  background: none;
  height: 42px;
  border: none;
  cursor: pointer;
}
.integration-settings-body__filter-option:focus, .integration-settings-body__filter-option:hover {
  color: #000000;
  font-family: "Volte Medium";
}
.integration-settings-body__filter-option.integration-settings-body__filter-option-selected {
  background-color: #f3f3f3;
  color: #000000;
}
.integration-settings-body__filter-option:active, .integration-settings-body__filter-option:focus {
  outline: none;
}

.integration-settings-body__tile-section {
  width: calc(100% - 226px);
}

.settings-tab--integrations .small-modal-background {
  z-index: 1000;
}

.integrations-oauth-redirect-modal .small-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.integrations-oauth-redirect-modal--loading .small-modal-content {
  height: calc(100% - 100px);
  justify-content: center;
}

.integrations-oauth-redirect-modal--ENABLED .small-modal-content {
  height: calc(100% - 200px);
  justify-content: center;
}

.integrations-oauth-redirect-modal-error {
  width: 80%;
}
.integrations-oauth-redirect-modal-error p {
  margin-bottom: 50px;
}

.integrations-setup-modal .small-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.integrations-setup-modal__header {
  font-family: "Volte Semibold";
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  margin: 0 0 8px 0;
}

.integrations-setup-modal__body {
  width: 80%;
}

.integrations-setup-modal__body__loading {
  margin: 50px 0 0 0;
}

.integrations-setup-modal-form__info {
  line-height: 25px;
  text-align: center;
}

.integrations-setup-modal-form__inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.integrations-setup-modal-form__inputs__input-group {
  width: 250px;
}
.integrations-setup-modal-form__inputs__input-group label {
  font-size: 12px;
}
.integrations-setup-modal-form__inputs__input-group input {
  height: 50px;
}

.integrations-setup-modal-form__inputs__input-group--margin-right {
  margin-right: 10px;
}

.integrations-setup-modal__success-animation {
  margin: 100px auto 0 !important;
}

.integrations-setup-modal__logos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 24px 0;
}

.integrations-setup-modal__logos__clyde {
  height: 100px;
  width: 100px;
}

.integrations-setup-modal__logos__arrows {
  margin: 0 16px;
}

.integrations-setup-modal__logos__other {
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f3f3f3;
  border-radius: 50%;
}

.integrations-setup-modal__logos__other__alert {
  height: 75px;
  width: 75px;
}

.integrations-setup-modal__buttons {
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0 0 0;
}

.integrations-setup-modal__buttons__connect {
  height: 50px !important;
  width: 200px !important;
  margin: 0 8px 0 0 !important;
}

.integrations-setup-modal__buttons__cancel {
  height: 50px;
  width: 97px;
  font-size: 16px;
  text-align: center;
  background: white;
  border: 2px solid #d1d1d1;
  border-radius: 8px;
}
.integrations-setup-modal__buttons__cancel:focus, .integrations-setup-modal__buttons__cancel:hover {
  outline: 0;
  border: 2px solid black;
}

.integrations-setup-modal__buttons__link {
  height: 50px;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #000000;
  overflow: hidden;
  text-align: center;
  font-family: "Volte";
  font-size: 16px;
  color: #ffffff !important;
  white-space: nowrap;
  border-radius: 5px;
  margin: 0 8px 0 0;
  cursor: pointer;
  transition: all 350ms ease;
  text-decoration: none;
}
.integrations-setup-modal__buttons__link:hover {
  color: #ffffff;
  background-color: #414141;
  border: 1px solid #414141;
  transition: all 350ms ease;
  text-decoration: none;
}
.integrations-setup-modal__buttons__link:focus {
  outline: 0;
  color: #ffffff;
  background-color: #414141;
  border: 1px solid #414141;
  transition: all 350ms ease;
  text-decoration: none;
}

.integrations-setup-modal-form__inputs__input-group-error.clyde-text-input {
  background: #fcf0f0;
  border-color: #f15a57;
}

.integrations-setup-modal-form__inputs__input-group-error {
  color: #f15a57;
}

div.integrations-setup-modal-form__inputs__input-group-error {
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.settings-tile__content--integrations {
  min-height: 50px;
}

.tile-loading-container {
  position: relative;
  top: 40px;
}

.settings-tile__content__section {
  margin-top: 6px;
}

.settings-tile__content--live {
  min-height: 50px;
  border-radius: 4px 4px 5px 5px;
  border: 2px solid #000000;
}

.settings-tile__content--test {
  min-height: 50px;
  border-radius: 4px 4px 5px 5px;
  border: 2px solid rgba(5, 195, 121, 0.5);
}

.small-switch--integrations {
  float: left;
  vertical-align: top;
}

.small-switch--integrations.small-switch--false {
  background: #909090;
}

.integrations-text-input-container {
  max-width: 200px;
}

.klaviyo-settings__info-icon {
  padding: 0px 10px;
  position: relative;
  cursor: pointer;
  margin-left: -5px;
  margin-right: 5px;
}

.remove-integration-modal {
  width: 400px !important;
  height: 300px !important;
  display: flex !important;
  flex-direction: column;
}

.remove-integration-modal-text {
  font-family: "Volte Semibold";
  font-size: 16px;
  margin: 0 0 8px 0;
}

.remove-integration-btn {
  margin: 30px auto;
}

.remove-integration-header {
  font-family: "Volte Semibold";
  font-size: 22px;
  margin: 0 0 20px 0;
}

.integrations-setup-modal__message {
  padding: 30px 50px;
}

.launch-processing-container {
  position: absolute;
  left: 60%;
  top: 18px;
}

.launch-processing-animation {
  color: #000000;
}

@keyframes launching-text-animation {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.launching-dots-container__dot {
  animation-name: launching-text-animation;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.launching-dots-container__dot:nth-child(2) {
  animation-delay: 0.2s;
}

.launching-dots-container__dot:nth-child(3) {
  animation-delay: 0.4s;
}

.product-tile {
  background: #ffffff;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  align-items: center;
}

.product-tile__record-sale-section {
  text-align: right;
}

.record-sale-button {
  display: inline-block;
  padding: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 34px;
  width: 120px;
  text-align: center;
  user-select: none;
  border: 1px solid #d9d9d9;
  margin-right: 0;
}

.record-sale-button:hover {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
}

.record-sale-button:active {
  box-shadow: none;
}

.name-section__edit {
  min-width: 14px;
  width: 14px;
  margin-right: 10px;
}

.name-section__thumb {
  width: 30px;
  margin-right: 20px;
  max-height: 30px;
  min-height: 30px;
  min-width: 30px;
  max-width: 100%;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .name-section__thumb {
    margin-right: 40px;
  }
  .name-section__edit {
    margin-right: 20px;
  }
}
.name-section__name {
  font-family: "Volte Semibold";
  font-size: 18px;
}

.padding-left-0 {
  padding-left: 0;
}

.product-tile__expand-section {
  text-align: right;
}

.expand-section__container {
  padding: 5px;
  margin-right: 10px;
  display: inline-block;
}

.product-tile__contracts-text {
  font-size: 14px;
  line-height: 20px;
  margin-right: 20px;
  user-select: none;
}

.product-tile__contracts-caret,
.product-tile__variants-caret {
  width: 11px;
  cursor: pointer;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.product-tile__contracts-caret--right,
.product-tile__variants-caret--right {
  transform: rotate(0deg);
}

.product-tile__contracts-caret--down,
.product-tile__variants-caret--down {
  transform: rotate(90deg);
}

.clyde-contracts-container {
  margin: 10px 0;
}

.clyde-contracts {
  margin-left: -15px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 0;
}

.clyde-contract-row {
  padding-top: 8px;
  padding-bottom: 5px;
  margin-left: 0;
  align-items: center;
}

.clyde-contract-row__name {
  padding-left: 32px;
  color: #000000;
  transition: all 100ms ease;
  text-decoration: underline;
}

.clyde-contract-row__name:hover {
  color: #414141;
  text-decoration: underline;
}

.list-view-variants {
  border-top: 1px solid #d7d7d7;
  padding-top: 10px;
  margin-top: 10px;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

img[alt="clyde logo"] {
  margin-bottom: 25px;
}

.login-layout {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  margin-top: 0;
  margin-left: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 20px;
}

.login-tile {
  text-align: center;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 30px 90px;
  margin-top: 60px;
  text-align: center;
}

.login-tile__password-reset-button {
  cursor: pointer;
  margin-top: 10px;
  color: #000000;
  background: none;
}
.login-tile__password-reset-button:focus {
  outline: none;
}
.login-tile__password-reset-button:hover {
  color: #414141;
}

.login-tile__tagline {
  line-height: 30px;
  font-size: 24px;
  letter-spacing: -0.63px;
}

.select-platform-tile {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  z-index: 1;
  color: #000000;
  margin-top: 20px;
  padding: 20px 30px 30px 30px;
}

.platform-list {
  margin-right: -3px;
  margin-left: -3px;
}

.platform-container {
  padding-right: 3px;
  padding-left: 3px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.platform-option {
  display: block;
  color: #000000;
  text-decoration: none;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 15px;
}

.platform-option:hover {
  color: #000000;
  text-decoration: none;
}

.platform-option__name {
  font-size: 20px;
  line-height: 30px;
}

.platform-option__logo {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  position: relative;
  top: -2px;
}

.clyde-login-form,
.shopify-login-form {
  margin: 20px 0 10px 0;
}

.clyde-login-form__feedback {
  text-align: left;
  color: red;
}

.shopify-login-form__input {
  font-size: 16px;
  line-height: 45px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px 5px 0 0px;
  text-align: center;
  height: 50px;
  margin-bottom: 0;
  padding: 0;
}

.clyde-login-form__input {
  border-radius: 5px;
  line-height: 16px;
  height: 40px;
  margin: 5px 0;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  text-align: center;
}

.clyde-login-form__input:disabled {
  background-color: #eee;
}

.clyde-login-form__button,
.shopify-login-form__button {
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #ffffff;
  letter-spacing: -0.5px;
  background: #000000;
  cursor: pointer;
  border: 1px solid #000000;
  color: #fff;
  border-radius: 0 0 5px 5px;
}
.clyde-login-form__button:hover,
.shopify-login-form__button:hover {
  background: #414141;
}

.clyde-login-form__button {
  margin-top: 5px;
  border-radius: 5px;
}

.login-form__error {
  color: red;
  margin-top: 5px;
}

.nav-bar__clyde-logo {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  user-select: none;
  background: linear-gradient(90deg, #feed7a 0%, #ff8400 49.87%, #df91ff 100%);
}

.nav-bar__clyde-logo__image {
  width: 22px;
  margin-top: 1px;
}

.nav-bar__top {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 50px;
  height: 51px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  user-select: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.08);
}

.nav-bar__top__content {
  width: 80%;
  margin: auto;
}

.nav-bar-page-name {
  position: absolute;
  right: 10%;
  top: 18px;
  color: #949494;
}

.nav-bar-logout {
  position: fixed;
  top: 0;
  right: 0;
  height: 46px;
  margin: 2px;
  border-radius: 30px;
  transition: background-color 200ms ease;
  cursor: pointer;
}

.nav-bar-logout__img {
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.nav-bar-logout:hover {
  background-color: #f3f3f3;
}

.nav-bar__left {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000;
  width: 50px;
  background-color: #000000;
  user-select: none;
  background-image: -webkit-linear-gradient(270deg, rgba(22, 204, 187, 0.11), rgba(22, 204, 187, 0.11));
  background-image: linear-gradient(180deg, rgba(22, 204, 187, 0.11), rgba(22, 204, 187, 0.11));
}

.nav-bar__left__content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.nav-buttons {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  padding-top: 80px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.nav-buttons-bottom {
  position: absolute;
  bottom: 0;
}

.nav-buttons-bottom__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  text-align: center;
}

.nav-buttons-bottom__item--support {
  height: 30px;
}

.nav-buttons-bottom__item__image {
  max-width: 28px;
}

.nav-buttons-bottom__item__image--support {
  max-width: 20px;
}

.nav-buttons__item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  position: relative;
}

.nav-buttons__item--active {
  background-color: #ffffff;
  box-shadow: 6px 0 11px 0 rgba(0, 0, 0, 0.08);
}

.nav-buttons__icon--active {
  filter: invert(100%) sepia(45%) saturate(28%) hue-rotate(42deg) brightness(108%) contrast(106%);
}

.nav-bar__tooltip__text {
  min-width: 140px;
}

.badge {
  width: 16px;
  height: 16px;
  background: #d52424;
  color: #ffffff;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 4px;
  padding: 3px 0;
}

.new-order-cart,
.new-order-cart__search,
.new-order-cart__search__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.new-order-cart {
  align-items: center;
}

.new-order-cart-search__text {
  margin: 0;
  border-bottom: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.new-order-cart__search__button {
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: solid 1px #d7d7d7;
  background-image: linear-gradient(to bottom, #ffffff, #f8f8f8);
  font-size: 15px;
  color: #909090;
}

.new-order-cart__search__button:focus,
.new-order-cart__search__button:hover {
  outline: 0;
  background-image: none;
  background-color: #f8f8f8;
}

.new-order-content {
  width: 100%;
  margin-top: 20px;
}

.new-order-content__header {
  display: none;
  font-family: Volte Semibold;
}

.new-order-content__header__right {
  min-width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.new-order-cart-item {
  height: 170px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #4a4a4a;
  margin-bottom: 25px;
}

.new-order-cart-item-remove {
  height: 20px;
  width: 20px;
  position: absolute;
  right: 21px;
  top: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: white;
  background-color: #c5c5c5;
  border-radius: 50%;
  padding-top: 4px;
  padding-left: 2px;
}

.new-order-cart-item-remove:hover,
.new-order-cart-item-remove:focus {
  outline: 0;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
}

.new-order-cart-item__left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.new-order-cart-item-img {
  max-height: 50px;
  max-width: 50px;
}

.new-order-cart-item-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-order-cart-item-info__name,
.new-order-cart-item-info__variant-name,
.new-order-cart-item-info__link {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-order-cart-item-info__name {
  font-family: Volte Semibold;
}

.new-order-cart-item-info__link {
  outline: 0;
  font-size: 14px;
}

.new-order-cart-item-info__link:focus {
  color: #0056b3;
}

.new-order-cart-item-info__link--uneligible {
  color: #909090;
}

.new-order-cart-item__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #4a4a4a;
}

.new-order-cart-header-quantity,
.new-order-cart-item-quantity {
  min-width: 95px;
}

.new-order-cart-item-quantity {
  max-width: 95px;
  background-color: white;
  margin: 0;
}

.new-order-cart-item-price {
  padding-right: 10px;
}

.new-order-cart-item-price__input {
  max-width: 95px;
  background-color: white;
  margin: 0;
}

.new-order-content__summary {
  width: 100%;
  margin-top: 30px;
}

.new-order-content__summary .cop-small-modal-line {
  margin: 10px 0 10px 0;
}

.new-order-content__summary__center {
  width: 100%;
}

.new-order-cart-summary__text {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Volte Semibold Italic;
}

.new-order-content__actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.new-order-content__actions__error {
  text-align: center;
  font-size: 14px;
  color: red;
  margin-bottom: 10px;
}

.new-order-content__actions__info {
  text-align: center;
  font-size: 14px;
  color: #909090;
  margin-bottom: 5px;
}

.new-order-button {
  height: 45px;
  min-width: 200px;
  width: 100%;
  max-width: 350px;
  font-family: Volte;
  text-align: center;
  border: solid 3px #000000;
  border-radius: 5px;
  padding: 5px 20px;
  margin-bottom: 10px;
  transition: all 200ms ease;
}

.new-order-button--empty {
  color: #000000;
  background-color: white;
}

.new-order-button--empty:hover,
.new-order-button--empty:focus {
  outline: 0;
  color: #414141;
  border: solid 3px #414141;
  transition: all 200ms ease;
}

.new-order-button--filled {
  color: white;
  background-color: #000000;
}

.new-order-button--filled:hover,
.new-order-button--filled:focus {
  outline: 0;
  background-color: #414141;
  border: solid 3px #414141;
  transition: all 200ms ease;
}

.customer-form-label--error {
  color: red;
}

.customer-form select {
  background-color: #f3f3f3;
}

.customer-form input:focus,
.customer-form select:focus {
  outline: 0;
  background-color: #d9d9d9;
}

.new-order-customer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media (min-width: 576px) {
  .new-order-cart-item-remove {
    right: 48px;
    top: 115px;
    padding-left: 1px;
  }
}
@media (min-width: 768px) {
  .new-order-cart-item {
    height: 65px;
    margin-bottom: 15px;
  }
  .new-order-cart-item-remove {
    right: -8px;
    top: 5px;
  }
  .new-order-cart-item-info {
    margin-left: 10px;
  }
  .new-order-content__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .new-order-cart-item__left {
    width: 40%;
    flex-direction: row;
    text-align: left;
  }
  .new-order-cart-item__right {
    width: initial;
    min-width: 200px;
    flex-direction: row;
    justify-content: space-between;
  }
  .new-order-cart__search__inputs {
    flex-direction: row;
  }
  .new-order-cart__search__label {
    width: 100%;
  }
  .new-order-cart-search__text {
    width: 100%;
    border-right: 0px;
    border-bottom: solid 1px #d7d7d7;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
  }
  .new-order-cart__search__button {
    min-width: 140px;
    width: 45%;
    max-width: 200px;
    border-top-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  .new-order-content__summary {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .new-order-content__summary__center {
    width: 80%;
  }
  .new-order-content__actions {
    margin-top: 60px;
  }
}
@media (min-width: 992px) {
  .new-order-cart-item__left {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .new-order-content__summary__center {
    width: 50%;
  }
  .new-order-cart-item__left {
    width: 60%;
  }
}
.new-product {
  top: 100px;
  height: 95%;
}

.new-product-modal {
  top: 5%;
  height: 95%;
  width: 70%;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  text-align: left;
  padding: 50px 60px;
}

.new-product-content {
  max-height: 60vh;
  overflow: auto;
}

.close-product-modal {
  cursor: pointer;
  float: right;
  color: #949494;
  font-size: 30px;
  line-height: 30px;
}

.new-product-header {
  text-align: left;
}

.new-product-header__title-container {
  margin-bottom: 30px;
}

.product-field-label {
  text-align: left;
  font-size: 15px;
  margin-bottom: 5px;
  margin-left: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.product-field-label--invalid,
.product-field-label--invalid-unique {
  color: #f15a57;
}

.product-field-label--invalid::after {
  content: " (required)";
}

.product-field-label--invalid-unique::after {
  content: " (not unique)";
}

.new-product .product-save-button {
  width: 200px;
  margin: 20px auto 0;
  box-shadow: none;
  font-weight: normal;
  float: none;
}

.product-save-button:active {
  box-shadow: 0px 0px 0px 0px #ffffff;
  outline: none;
}

.product-save-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
}

.product-image-upload-container {
  text-align: center;
  user-select: none;
  background: #f3f3f3;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  color: #909090;
  font-size: 15px;
  height: 105px;
  cursor: pointer;
}

.product-image-upload {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: #909090;
}

.product-image-upload__image {
  width: 20px;
}

.product-image-error {
  position: relative;
  top: 50px;
  font-size: 12px;
}

.sku-tooltip {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.reset-image {
  cursor: pointer;
  position: absolute;
  right: 15px;
  color: rgb(162, 162, 162);
}

.product-image-preview {
  text-align: center;
}

.product-image-preview__reset {
  cursor: pointer;
  position: absolute;
  right: 15px;
  color: rgb(162, 162, 162);
}

.product-image-preview__reset:hover {
  color: rgb(187, 187, 187);
}

.product-image-preview__image {
  max-width: 80%;
  max-height: 80px;
}

.hidden-input-container {
  display: none;
}

.new-product-parts__list:first-child {
  margin-top: 36px;
  padding-top: 36px;
  border-top: 1px dashed #d1d1d1;
}

.new-product-parts__controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 28px 0px;
}

.new-product-parts__add {
  display: flex;
  flex-direction: row;
}
.new-product-parts__add .circle {
  border-color: #000000;
}
.new-product-parts__add .circle.plus:before,
.new-product-parts__add .circle.plus:after {
  background: #000000;
}

.new-product-parts__add__text {
  text-decoration: underline;
  margin: 18px 6px;
}

.circle {
  border: 2px solid #d1d1d1;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 100%;
  position: relative;
  margin: auto 6px;
  display: flex;
  vertical-align: middle;
}

.circle.plus:before,
.circle.plus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d1d1d1;
}

.circle.plus:before {
  width: 2px;
  margin: 2px auto;
}

.circle.plus:after {
  margin: auto 2px;
  height: 2px;
}

.circle.minus:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #d1d1d1;
  margin: auto 2px;
  height: 2px;
}

.profile__claims-contact .col-12.col-lg-3.col-md-3.col-sm-6.col-xs-12:last-of-type {
  display: none;
}

.profile__warranty-info__radio-group {
  display: flex;
  align-items: center;
}
.profile__warranty-info__radio-group label {
  margin: 5px 10px;
}

.profile-label.profile-label--oem-length {
  margin-top: 54px;
}

.profile__last-row {
  margin-bottom: 70px;
}

.onboarding-instructions {
  display: block;
  width: 80%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 5px;
  background: #f3f3f3;
  color: #000000;
}

.onboarding-process-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  z-index: 20;
  margin-left: 50px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: #d9d9d9;
  background-color: #ffffff;
}

.onboarding-acknowledgement-modal,
.onboarding-warning-modal {
  top: 5%;
  height: 95%;
  width: 50%;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  text-align: left;
  padding: 50px 60px;
  font-size: 16px;
}

.onboarding-acknowledgement-modal {
  overflow-y: auto;
  height: 80%;
  width: 65%;
}

.clyde-modal-content.acknowledgement-modal-content {
  display: flex;
  height: 100%;
  top: 0;
}
.clyde-modal-content.acknowledgement-modal-content h2 {
  color: #727272;
}
.clyde-modal-content.acknowledgement-modal-content h1 {
  margin-bottom: 30px;
}
.clyde-modal-content.acknowledgement-modal-content hr {
  margin-bottom: 40px;
}

.clyde-checkbox.acknowledgement-modal__check {
  margin: 16px 0 40px;
}
.clyde-checkbox.acknowledgement-modal__check .clyde-checkbox-label {
  margin: 3px 0 0 5px;
}

.acknowledgement-modal__button {
  display: flex;
  align-items: center;
}

.clyde-button.acknowledgement-modal__button__button {
  width: auto;
  padding: 0 24px;
  margin-right: 16px;
}

.acknowledgement-modal__button__error {
  font-size: 13px;
  color: #d52424;
}

.onboarding-warning-modal__small-print {
  padding-top: 16px;
  font-size: 12px;
}

.clyde-button.onboarding-warning-modal__button {
  width: auto;
  margin: 50px auto 20px;
  padding: 0 40px;
  font-size: 20px;
}

.onboarding-process-content {
  position: static;
  left: 0px;
  width: 80%;
  bottom: 59px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.process-bar-container {
  position: relative;
  display: block;
}

.process-bar {
  height: 8px;
  width: 85%;
  border-radius: 20px;
  background-color: #414141;
}

.onboarding-step {
  position: absolute;
  left: 20%;
  top: -6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 100px;
  background-color: #d1d1d1;
  box-shadow: 0 0 0 3px #ffffff;
  cursor: pointer;
}

.onboarding-step--active {
  box-shadow: 0 0 0 3px #000000;
}

.onboarding-step--complete {
  background-color: #000000;
}

.onboarding-step--step-0 {
  left: 0%;
}

.onboarding-step--step-1 {
  left: 23%;
}

.onboarding-step--step-2 {
  left: 46%;
}

.onboarding-step--step-3 {
  left: 69%;
}

.onboarding-text {
  position: absolute;
  text-align: center;
  top: 5px;
  display: block;
  width: auto;
  margin-top: 26px;
  width: 100px;
}

.onboarding-text--active {
  font-weight: 700;
}

.process-button-container {
  position: relative;
  display: block;
  left: 86%;
}

.process-button {
  position: absolute;
  left: 15px;
  bottom: -18px;
  max-width: 147px;
  width: 15%;
  z-index: 1000;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}

.welcome-header {
  font-size: 38px;
  padding: 20px 0;
  font-weight: bold;
}

.welcome-step-container {
  margin-left: 30px;
}

.welcome-step {
  padding: 20px 0;
  font-size: 18px;
  letter-spacing: -0.38px;
}

.welcome-step__image-container {
  text-align: center;
}

.welcome-step__image-container__image {
  min-width: 85px;
  margin-bottom: 5px;
}

.welcome-step-text {
  font-weight: bold;
}

.orders {
  min-width: 800px;
  display: flex;
  flex-direction: column;
}

.orders__back {
  align-self: flex-start;
  margin: -20px 0 20px -48px;
}

.orders__back__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: text-shadow 0.2s ease, background-color 0.2s ease;
}

.orders__back__button:focus,
.orders__back__button:hover {
  outline: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: text-shadow 0.2s ease;
}
.orders__back__button:focus .orders__back__button__image,
.orders__back__button:hover .orders__back__button__image {
  background-color: #414141;
  transition: background-color 0.2s ease;
}

.orders__back__button__image {
  height: 30px;
  width: 30px;
  display: inline-block;
  background-color: #000000;
  border-radius: 50%;
  margin-right: 10px;
}

.orders__back__button__image__arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin: 9px 0 0 11px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.orders__back__button__text {
  color: #5a5a5a;
}

.orders__header {
  width: 100%;
  margin: 0 0 5px 0;
}

.orders__info {
  min-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  color: #4a4a4a;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 20px 30px;
  word-wrap: break-word;
}

.orders__info__content {
  min-width: 150px;
  margin: 0 20px;
}
.orders__info__content h6 {
  font-family: Volte Semibold;
  font-size: 16px;
  margin: 0;
}
.orders__info__content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.orders__info__content__spinner {
  transform: scale(0.5);
}

.orders__list,
.product-registrations__list {
  min-width: 800px;
  width: 100%;
  margin-top: 20px;
}

.orders__list__table-header,
.orders__list__table-body,
.product-registrations__list__table-header,
.product-registrations__list__table-body {
  min-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.orders__list__table-header,
.product-registrations__list__table-header {
  font-family: Volte Semibold;
  font-size: 15px;
}

.orders__list__table-body,
.product-registrations__list__table-body {
  font-size: 13px;
  color: #4a4a4a;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
}

.orders__list__table-body--loading {
  justify-content: center;
}

.orders__list__table-header__row,
.orders__list__table-body__row,
.product-registrations__list__table-header__row,
.product-registrations__list__table-body__row {
  display: flex;
  flex-direction: row;
}

.orders__list__table-header__row,
.product-registrations__list__table-header__row {
  padding: 0 25px 0 25px;
}

.orders__list__table-body__row,
.product-registrations__list__table-body__row {
  border-bottom: solid 1px #d7d7d7;
  transition: box-shadow 0.2s ease, background-color 0.2s ease;
  padding: 15px 25px;
}

.orders__list__table-body__row:last-child,
.product-registrations__list__table-body__row:last-child {
  border-bottom: none;
}

.orders__list__table-body__row:first-child,
.product-registrations__list__table-body__row:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.orders__list__table-body__row:last-child,
.product-registrations__list__table-body__row:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.orders__list__table-body__row--test {
  background-color: #ffd6a1;
}

.orders__list__table-body__row--highlighted {
  background-color: #d1dffa;
}

.test-order-flag {
  color: #ff4538;
}

.orders__list__table-body__row:focus,
.orders__list__table-body__row:hover,
.product-registrations__list__table-body__row__clickable:focus,
.product-registrations__list__table-body__row__clickable:hover {
  outline: 0;
  background-color: #eeeeee;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  transform: scale(1.01, 1.05);
  transition: box-shadow 0.2s ease, background-color 0.2s ease, transform 0.2s ease;
  cursor: pointer;
  border-radius: 5px;
}

.order__list__table-body__row__tooltip__child {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  align-self: flex-start;
}

.clyde-tooltip-container {
  width: auto !important;
  height: auto !important;
}

.orders__list__table-header__row__cell-id,
.orders__list__table-body__row__cell-id {
  width: 31%;
}

.orders__list__table-header__row__cell-date,
.orders__list__table-body__row__cell-date {
  width: 10%;
}

.orders__list__table-header__row__cell-products,
.orders__list__table-body__row__cell-products {
  width: 29%;
}

.orders__list__table-header__row__cell-eligible,
.orders__list__table-body__row__cell-eligible {
  width: 10%;
}

.orders__list__table-header__row__cell-contracts,
.orders__list__table-body__row__cell-contracts {
  width: 10%;
}

.orders__list__table-header__row__cell-claims,
.orders__list__table-body__row__cell-claims {
  width: 10%;
}

.product-registrations__list__table-header__row__cell-product,
.product-registrations__list__table-body__row__cell-product,
.product-registrations__list__table-header__row__cell-date,
.product-registrations__list__table-body__row__cell-date {
  width: 22%;
}

.product-registrations__list__table-header__row__cell-pop,
.product-registrations__list__table-body__row__cell-pop {
  width: 20%;
}

.product-registrations__list__table-header__row__cell-contracts,
.product-registrations__list__table-body__row__cell-contracts,
.product-registrations__list__table-body__row__cell-serial-number,
.product-registrations__list__table-header__row__cell-serial-number,
.product-registrations__list__table-header__row__cell-claims,
.product-registrations__list__table-body__row__cell-claims {
  width: 15%;
}

.orders__list__table-body__row__cell-id,
.orders__list__table-body__row__cell-date,
.product-registrations__list__table-body__row__cell-product,
.product-registrations__list__table-body__row__cell-date {
  padding-right: 10px;
}

.orders__list__table-header__row__cell-id,
.orders__list__table-header__row__cell-date,
.orders__list__table-header__row__cell-products,
.orders__list__table-header__row__cell-eligible,
.orders__list__table-header__row__cell-contracts,
.orders__list__table-header__row__cell-claims,
.product-registrations__list__table-header__row__cell-product,
.product-registrations__list__table-header__row__cell-date,
.product-registrations__list__table-header__row__cell-pop,
.product-registrations__list__table-header__row__cell-serial-number,
.product-registrations__list__table-header__row__cell-contracts,
.product-registrations__list__table-header__row__cell-claims {
  display: flex;
  align-items: flex-end;
}

.orders__list__table-header__row__cell-date,
.product-registrations__list__table-header__row__cell-date {
  justify-content: flex-start;
  white-space: normal;
  padding: 0 12px 0 0;
}

.orders__list__table-header__row__cell-eligible,
.orders__list__table-header__row__cell-contracts,
.orders__list__table-header__row__cell-claims,
.product-registrations__list__table-header__row__cell-contracts,
.product-registrations__list__table-header__row__cell-claims {
  justify-content: center;
  white-space: normal;
}

.product-registrations__list__table-header__row__cell-serial-number {
  white-space: normal;
}

.orders__list__table-header__row__cell-eligible,
.orders__list__table-header__row__cell-contracts,
.orders__list__table-header__row__cell-claims,
.orders__list__table-body__row__cell-eligible,
.orders__list__table-body__row__cell-contracts,
.orders__list__table-body__row__cell-claims,
.product-registrations__list__table-header__row__cell-contracts,
.product-registrations__list__table-header__row__cell-claims,
.product-registrations__list__table-body__row__cell-contracts,
.product-registrations__list__table-body__row__cell-claims {
  text-align: center;
  padding: 0 10px;
}

.orders__list__table-body__row__cell-id,
.orders__list__table-body__row__cell-products,
.product-registrations__list__table-body__row__cell-id,
.product-registrations__list__table-body__row__cell-serial-number,
.product-registrations__list__table-body__row__cell-product {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders__list__table-body__row__cell-left {
  font-family: Volte Medium;
}

.performance-calendar-row {
  position: relative;
}

.performance-calendar--invisible {
  display: none;
}

.performance-calendar--visible {
  display: block;
  background: #f3f3f3;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -250px;
  margin-left: -150px;
  z-index: 150000;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
}

.rdr-DateRange {
  width: 300px;
  display: flex !important;
  flex-direction: column-reverse;
  align-items: center;
}

.rdr-PredefinedRanges {
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100% !important;
  padding-top: 10px;
  order: 1;
}

.rdr-PredefinedRangesItem {
  width: 40%;
}

.rdr-PredefinedRangesItem:hover {
  color: #000000 !important;
  background: #d7d7d7 !important;
}

.rdr-PredefinedRangesItemActive {
  color: #000000 !important;
}

.rdr-MonthAndYear {
  cursor: default;
  user-select: none;
}

.rdr-MonthAndYear-button {
  cursor: pointer;
}

.rdr-MonthAndYear-innerWrapper {
  font-family: Volte Semibold;
}

.rdr-WeekDays {
  font-family: Volte Medium;
}

.rdr-Day.is-passive {
  cursor: default !important;
}

.is-selected,
.is-selected.is-inRange {
  background: #000000 !important;
  color: white !important;
}

.is-inRange {
  background: #d9d9d9 !important;
  color: #000000 !important;
}

.performance-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.performance-input__date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.performance-input__date:hover,
.performance-input__date:focus {
  outline: 0;
}
.performance-input__date:hover:hover .performance-input__date__group-label,
.performance-input__date:focus:hover .performance-input__date__group-label {
  color: #000000;
}
.performance-input__date:hover:focus .performance-input__date__group-label,
.performance-input__date:focus:focus .performance-input__date__group-label {
  color: #000000;
}

.performance-input__date__group {
  width: 200px;
}

.performance-input__date__group-label {
  display: block;
  margin-bottom: 5px;
  color: #909090;
  line-height: 1em;
  font-weight: 400;
}

.performance-input__date__group-date {
  height: 38px;
  text-align: center;
  font-size: 16px;
  margin: 0 0 15px 0;
  cursor: pointer;
  user-select: none;
  padding: 6px 10px;
  border-style: none;
  border-radius: 5px;
  background-color: #f3f3f3;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  transition: all 200ms ease;
}

.performance-input__graph {
  display: flex;
  flex-direction: column;
  margin-bottom: 9px;
}

.button-graph-type {
  height: 38px;
  width: 136px;
  font-size: 14px;
  color: black;
  line-height: 34px;
  text-align: center;
  background-color: #f3f3f3;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.button-graph-type:focus,
.button-graph-type:hover {
  outline: 0;
  text-decoration: underline;
}

.button-graph-type--total {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.button-graph-type--week {
  border-top: 0;
  border-bottom: 0;
  border-radius: 0px;
}

.button-graph-type--day {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.button-graph-type--selected {
  color: white;
  font-weight: bold;
  background-color: #000000;
  border: 1px solid #3a5998 !important;
  box-shadow: inset 0 0 12px #555;
  pointer-events: none;
}

.performance-data-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.performance-data-tile {
  width: 100%;
  background: #ffffff;
  border: 2px solid #d9d9d9;
  border-radius: 5px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 0;
  transition: all 300ms ease;
  cursor: pointer;
}
.performance-data-tile:focus, .performance-data-tile:hover {
  outline: 0;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
}
.performance-data-tile:active {
  box-shadow: none;
}

.performance-data-tile--selected {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.12);
  border: solid 2px #000000;
}

.performance-data-tile--no-select {
  cursor: default;
}

.performance-data-tile--no-select:focus,
.performance-data-tile--no-select:hover {
  box-shadow: none;
}

.performance-data-tile__loading {
  transform: scale(0.5);
}

.performance-data-tile__title {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  font-size: 16px;
  font-family: "Volte Semibold";
  height: 40px;
}

.performance-data-tile__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  color: #000000;
  letter-spacing: -1px;
}

.performance-data-tile__list {
  text-align: center;
  color: black;
  font-size: 14px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.performance-data-tile__list__main {
  color: #e07400;
  font-size: 26px;
}

.performance-data-tile__list__title {
  font-family: Volte Medium;
  margin-right: 5px;
}

.performance-graph-tile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  user-select: none;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.performance-graph-tile__title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: "Volte Semibold";
  text-align: center;
  border-bottom: none;
  margin-bottom: 5px;
  margin-top: 20px;
}

.performance-data-tile__title__info {
  outline: 0;
  margin-left: 5px;
}

.tooltip-text--performance p {
  font-size: 14px;
}

.VictoryContainer svg {
  overflow: visible;
}

.performance-change-type-input {
  display: flex;
  flex-direction: column;
  margin: 10px 0 15px 0;
}

.performance-change-type-input__group {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 5px;
}

.performance-toggle-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  margin: 3px 0 0 0;
}

.performance-toggle-line {
  height: 20px;
  width: 40px;
  margin-right: 5px;
}

@media (min-width: 576px) {
  .performance-input__date {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .rdr-DateRange {
    width: 388px;
  }
  .performance-calendar--visible {
    margin-top: -190px;
    top: 143px;
    left: -45px;
  }
  .performance-input__graph {
    flex-direction: row;
  }
  .button-graph-type--total {
    border-top-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 5px;
  }
  .button-graph-type--week {
    border-top: 1px solid #d9d9d9;
    border-right: 0;
    border-bottom: 1px solid #d9d9d9;
    border-left: 0;
    border-radius: 0px;
  }
  .button-graph-type--day {
    border-top-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 0px;
  }
}
@media (min-width: 768px) {
  .performance-input {
    width: 100%;
  }
  .performance-input__date {
    width: 408px;
    margin-bottom: 15px;
  }
  .performance-input__date__group-date {
    margin-bottom: 0;
  }
  .performance-input__date__group:last-child {
    margin-right: 0;
  }
  .performance-data-tile {
    max-width: 49%;
  }
  .performance-data-tile:nth-child(5) {
    margin-left: auto;
    margin-right: auto;
  }
  .performance-calendar-row {
    width: 100%;
  }
  .performance-calendar--visible {
    left: auto;
    right: 0px;
    margin-top: -146px;
  }
  .performance-change-type-input {
    width: 100%;
    max-width: 600px;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
  }
}
@media (min-width: 992px) {
  .rdr-DateRange {
    width: 690px !important;
    flex-direction: row;
  }
  .rdr-PredefinedRanges {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 280px;
    width: 130px !important;
    padding-top: 0;
    padding-left: 10px;
    order: 0;
  }
  .rdr-PredefinedRangesItem {
    width: 100%;
    margin: 0 !important;
  }
  .performance-input__date {
    justify-content: flex-end;
    margin: 0;
  }
  .performance-input__date__group {
    width: 45%;
  }
  .performance-input__date__group:first-child {
    margin-right: 8px;
  }
  .performance-input {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
  }
  .performance-input__graph {
    order: -1;
    margin: 0 7px 0 0;
  }
  .performance-data-tile {
    max-width: 32%;
  }
  .performance-data-tile:nth-child(4),
  .performance-data-tile:nth-child(5) {
    max-width: 36%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .performance-input__date__group {
    width: 200px;
  }
  .performance-tile__content {
    font-size: 26px;
  }
  .performance-data-tile__title {
    font-size: 16px;
  }
}
@media (min-width: 1440px) {
  .performance-data-tile {
    max-width: 19%;
    margin-left: inherit;
    margin-right: inherit;
  }
  .performance-data-tile:nth-child(4),
  .performance-data-tile:nth-child(5) {
    max-width: 19%;
    margin-left: inherit;
    margin-right: inherit;
  }
}
.payment-form h2 {
  font-family: "Volte Semibold";
  margin: 1em 0;
  font-size: 1.25em;
}
.payment-form p.error {
  font-family: "Volte Medium";
  color: #d52424;
  margin: -1em 0 1em;
}
.payment-form .button-toolbar {
  overflow: hidden;
}
.payment-form .button-toolbar button {
  float: right;
}

.post-purchase-switch {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 100px;
  width: 50px;
  height: 22px;
  transition: all 250ms ease;
}

.post-purchase-switch--false {
  background: #909090;
  text-align: right;
}

.post-purchase-switch--true {
  background: #000000;
}

.post-purchase-switch__dot__text {
  color: #ffffff;
  font-family: "Volte";
  font-size: 12px;
}

.post-purchase-switch__dot__text--false {
  position: absolute;
  right: 20px;
  top: -1px;
}

.post-purchase-switch__dot__text--true {
  position: absolute;
  left: 18px;
  top: -1px;
}

.post-purchase-switch__dot {
  background: #f3f3f3;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 4.3px;
}

@media screen and (min-width: 992px) {
  .post-purchase-switch {
    float: right;
  }
  .post-purchase-switch {
    margin: 0;
  }
}
.product-filters-row {
  width: 80%;
  margin: 10px 0 1px -20px;
}

.col-6.product-filter--padding-override,
.col-3.product-filter--padding-override {
  padding-right: 0;
}

.product-filter {
  display: inline-block;
  width: 100%;
  text-align: left;
  margin: 0 5px;
  font-size: 14px;
}

.product-filter--search {
  margin-top: 28px;
  width: 100%;
}

.product-filter__export {
  background: #ffffff url("/images/download-arrow.svg") no-repeat 92% 50%;
  background-size: auto 48%;
  height: 38px;
  width: 175px;
  padding: 9px 9px 10px 12px;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: -webkit-box-shadow 300ms ease;
  transition: -webkit-box-shadow 300ms ease;
  -o-transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease;
  transition: box-shadow 300ms ease, -webkit-box-shadow 300ms ease;
  border: 1px solid #d7d7d7;
  border-radius: 8px;
}

.product-search {
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  top: -14.5px;
}

.hidden-input {
  display: none;
}

.cop-item-serial__input {
  height: 30px !important;
  width: 90% !important;
  font-size: 14px !important;
  margin: 5px 0;
}

.cop-item-serial__input--disabled {
  cursor: default !important;
}

.cop-item-serial__tag {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-family: "Volte";
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  border-radius: 2px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 3px 5px;
  cursor: default;
}

.cop-item-serial__tag--product {
  background-color: #d1dffa;
  border: 1px solid #000000;
}

.cop-item-serial__tag--contract {
  background-color: #d1dffa;
  border: 1px solid #000000;
}

.cop-item-serial__tag__remove-button {
  font-family: "Volte Bold";
  font-size: 10px;
  margin: 0 0 0 5px;
}
.cop-item-serial__tag__remove-button:hover, .cop-item-serial__tag__remove-button:focus {
  outline: 0;
  color: #d52424;
}

.product-pagination-row--top {
  position: absolute;
  right: 0;
  top: -64px;
}
.product-pagination-row--top.product-pagination-row__registrable-products-active {
  top: -59px;
}

@media (min-width: 1124px) {
  .product-pagination-row--top {
    position: absolute;
    right: 0;
    top: -54px;
  }
  .product-pagination-row--top.product-pagination-row__registrable-products-active {
    top: -49px;
  }
}
.product-pagination-row--bottom {
  margin-bottom: 40px;
  padding-top: 10px;
}

.product-pagination-row ul {
  float: right;
}

.page-item.active .page-link {
  background-color: #000000;
  border-color: #000000;
  z-index: 0;
}

.page-link {
  text-decoration: none;
  color: #000000;
}
.page-link:active {
  outline: none;
}
.page-link:hover {
  color: #000000;
}

.products-loading-animation-container {
  height: 500px;
  width: 100%;
  margin: auto;
  text-align: center;
}

.product-switch {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 100px;
  width: 52px;
  height: 32px;
  line-height: 20px;
  float: right;
  transition: all 250ms ease;
}

.product-switch--on {
  background: #000000;
}

.product-switch--off {
  background: #909090;
}

.product-switch--partial {
  background: #414141;
}

.product-switch__contents {
  background: #fbfbfb;
  width: 23px;
  height: 23px;
  border-radius: 12px;
  position: absolute;
  top: 4.3px;
}

.product-switch__contents__on {
  color: #fbfbfb;
  font-family: "Volte";
  font-size: 8px;
  position: absolute;
  left: -16px;
  top: 2.3px;
}

.product-switch__contents__off {
  color: #fbfbfb;
  font-family: "Volte";
  font-size: 8px;
  position: absolute;
  right: -19.5px;
  top: 2.3px;
}

.product-no-action {
  border-radius: 100px;
  width: 50px;
  height: 30px;
  line-height: 24px;
  color: #fbfbfb;
  user-select: none;
  float: right;
  text-align: center;
}

.product-no-action--red {
  background-color: #f15a57;
  color: #ffffff;
}

.product-no-action--green {
  background-color: #000000;
}

.product-tile__name-section {
  display: flex;
  align-items: center;
}

.upload-products {
  top: 5%;
  height: 95%;
}

.upload-products-modal-content {
  top: 5%;
  height: 95%;
  width: 65%;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin: auto;
  text-align: left;
  padding: 50px 60px;
}

.close-upload-modal {
  cursor: pointer;
  float: right;
  color: #949494;
  font-size: 30px;
  line-height: 30px;
  cursor: pointer;
}

.import-products-prefixed-subtitle {
  font-size: 16px;
  color: #000000;
}

.import-products-title {
  font-size: 38px;
  color: #000000;
}

.import-steps {
  padding-top: 30px;
  padding-bottom: 30px;
}

.import-step__button {
  min-height: 170px;
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  align-items: center;
  display: flex;
  cursor: pointer;
  user-select: none;
  font-size: 15px;
  color: #909090;
  text-decoration: none;
  transition-property: box-shadow, background;
  transition-duration: 200ms;
}

.import-step__button:hover {
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
}

.import-step__button:active {
  box-shadow: none;
}

.import-csv-error {
  font-size: 12px;
  color: #f15a57;
}

.import-step__button--submitted {
  box-shadow: none;
  cursor: default;
  background: #fafafa !important;
}

.import-step__submit-button.import-step__button--disabled {
  background: #f3f3f3;
  cursor: default;
  color: #000000;
}

.import-step__submit-button.import-step__button--submitted {
  color: #909090;
  border-color: #909090;
  box-shadow: none;
}

.import-step__button--submitted {
  color: #909090 !important;
}

.import-step__button__content {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.import-step-image {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

.import-step__description {
  font-size: 16px;
  color: #000000;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.import-step__submit-button {
  color: #ffffff;
  background: #000000;
  border: 1px solid #000000;
}

.import-progress {
  text-align: center;
  width: 100%;
  margin-top: 20px;
}

.import-progress--finish {
  margin-top: 40px;
}

.finish-button-row {
  margin-top: 40px;
}

.finish-buttons {
  text-align: right;
}

.finish-buttons .upload-button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px !important;
  margin-left: 5px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}

.upload-button:active {
  box-shadow: 0px 0px 0px 0px #ffffff;
  outline: none;
}

.upload-button:hover {
  box-shadow: -2px 2px 8px 0px #ffffff;
}

.finish-buttons .upload-button--red {
  background: #f15a57;
  border-color: #f15a57;
}

.finish-buttons .upload-button--red:hover {
  background: #d11511;
  border-color: #d11511;
}

.expand-contracts-caret {
  text-align: right;
  width: 6px;
  margin-left: 12px;
  margin-top: -5px;
  cursor: pointer;
  -moz-transition: all 200ms ease;
  -webkit-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.expand-contracts-caret--right {
  transform: rotate(0deg);
}

.expand-contracts-caret--down {
  transform: rotate(90deg);
}

.variant-row {
  align-items: center;
  width: 100%;
}

.expand-contracts-text {
  text-align: right;
}

.expand-contracts-text--matched {
  cursor: pointer;
}

.variant-switch-container {
  text-align: right;
}

.contracts-collapse-container {
  align-items: center;
  width: 100%;
  padding: 0%;
}

.variant-contracts-list {
  margin-top: 8px;
  margin-bottom: 10px;
}

.variant-contracts-list__row {
  align-items: center;
}

.variant-contract__tooltip__icon {
  cursor: pointer;
  margin: -20px 0 0 60px;
}

.variant-contract__tooltip__text {
  max-width: 175px;
  margin: 5px 0;
  font-size: 14px;
  z-index: 1000;
  line-height: 16px;
  white-space: normal;
  text-align: left;
}

.variant-row__name-section {
  display: flex;
}
.variant-row__name-section.offset-1 {
  margin-left: 2.5rem;
}

.product-variant__registrable-products-checkbox {
  margin-right: 10px;
}

.launch-instruction__text {
  max-width: 300px;
  font-size: 16px;
  margin: 10px 0;
  user-select: none;
}

.launch-instruction__close {
  cursor: pointer;
  user-select: none;
  float: right;
  font-size: 16px;
  padding: 1px;
  z-index: 1000;
}

.toggle-launch-instructions {
  position: relative;
  left: 50%;
  width: 0;
  bottom: 45px;
}

.contract-settings-link {
  color: #909090;
  margin: 20px;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-decoration: none;
}

.contract-settings-link:active {
  outline: none;
}

.contract-settings-link:hover {
  background: none;
  text-decoration: none;
}

.products-save-button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.products-save-button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.products-save-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
  background: #000000;
}

.rate-sheet-export-button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: left;
  margin-top: 20px;
  background: #000000;
  border-radius: 5px;
  width: 180px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.rate-sheet-export-button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.rate-sheet-export-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
  background: #414141;
}

.products-view {
  min-width: 1150px;
  display: flex;
  flex-direction: column;
}
.products-view h2 {
  font-family: Volte Semibold;
  font-size: 28px;
}
.products-view h6 {
  margin: 0;
}
.products-view ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.products-view a:focus {
  color: #0056b3;
  outline: 0;
}

.products-view__back {
  align-self: flex-start;
  margin: -20px 0 20px -47px;
}

.products-view__back__button {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: text-shadow 0.2s ease, background-color 0.2s ease;
}

.products-view__back__button:focus,
.products-view__back__button:hover {
  outline: 0;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: text-shadow 0.2s ease;
}
.products-view__back__button:focus .products-view__back__button__image,
.products-view__back__button:hover .products-view__back__button__image {
  background-color: #414141;
  transition: background-color 0.2s ease;
}

.products-view__back__button__image {
  height: 30px;
  width: 30px;
  display: inline-block;
  background-color: #000000;
  border-radius: 50%;
  margin-right: 10px;
}

.products-view__back__button__image__arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
  margin: 9px 0 0 11px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.products-view__back__button__text {
  color: #5a5a5a;
}

.products-view__eligible-registrable-products-button {
  width: 256px;
  height: 38px;
  border: 1px solid #d1d1d1;
  background-color: white;
  color: black;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  right: 15px;
  top: 24px;
}
.products-view__eligible-registrable-products-button:hover {
  background-color: #f6f6f6;
  outline: 0;
}
.products-view__eligible-registrable-products-button:focus {
  outline: 0;
}

.product-view__registrable-products-checkbox {
  margin: 0 20px;
  min-width: 16px;
  min-height: 16px;
}
.product-view__registrable-products-checkbox:disabled {
  background-color: #d1d1d1;
  color: white;
}

.products-view__download-button {
  margin-top: 20px !important;
}

.product-tile__name-section .product-view__registrable-products-checkbox {
  margin: 0 20px 0 -5px;
}

.products-view__registrable-products-row {
  justify-content: space-between;
  align-items: center;
}

.products-view__registrable-products-row-section {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 10px;
}

.products-view__eligible-registrable-products-button-toggled {
  border: 1px solid #000000;
  background-color: white;
  color: #000000;
}

.products-view__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 5px 0;
}

.products-view__header__title {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.products-view__header__title h1 {
  display: inline-block;
  margin-bottom: 5px;
}
.products-view__header__title span {
  font-family: Volte;
  font-size: 16px;
}

.products-view__header__actions {
  position: relative;
}

.products-view__header__actions__menu {
  width: 300px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 188px;
  right: 128px;
  z-index: 10000;
}
.products-view__header__actions__menu li {
  height: 50px;
  width: 100%;
}

.products-view__header__actions__menu__button {
  height: 100%;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
}

.products-view__header__actions__menu__button:focus,
.products-view__header__actions__menu__button:hover {
  outline: 0;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.products-view__info {
  min-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  color: #4a4a4a;
  background-color: white;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 20px 30px;
  word-wrap: break-word;
}

.products-view__info__content {
  min-width: 150px;
  margin: 0 20px;
}
.products-view__info__content h6 {
  font-family: Volte Semibold;
  font-size: 16px;
}
.products-view__info__content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.products-view__info__content--hiclyde__pp-header {
  margin-top: 10px !important;
}

.products-view__info__content__spinner {
  transform: scale(0.5);
}

.products-view__info__content--transfer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.products-view__active-contracts,
.products-view__eligible-products,
.products-view__expired-contracts,
.products-view__ineligible-products {
  width: 100%;
  margin: 30px 0;
}

.products-view__active-contracts {
  margin-top: 20px;
}
.products-view__active-contracts h2 {
  color: #000000;
}

.products-view__active-contracts__header,
.products-view__eligible-products__header,
.products-view__expired-contracts__header,
.products-view__eligible-products__header,
.products-view__ineligible-products__header,
.products-view__meta__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.products-view__active-contracts__header h2,
.products-view__eligible-products__header h2,
.products-view__expired-contracts__header h2,
.products-view__eligible-products__header h2,
.products-view__ineligible-products__header h2,
.products-view__meta__header h2 {
  color: #949494;
}

.products-view__active-contracts__header h2 {
  color: #000000;
}

.products-view__eligible-products__header h2 {
  color: #949494;
}

.eligible-products,
.ineligible-products,
.active-contracts,
.expired-contracts {
  min-width: 1150px;
  font-size: 15px;
  background-color: white;
  border-radius: 5px;
  margin: 0 0 10px 0;
}

.active-contracts {
  border: 1px solid #000000;
}

.eligible-products {
  border: 1px solid #000000;
}

.ineligible-products {
  border: 1px solid #5c5c5c;
}

.expired-contracts {
  border: 1px solid #5c5c5c;
}

.eligible-products__content,
.ineligible-products__content,
.active-contracts__toggle,
.expired-contracts__toggle {
  min-height: 105px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #4a4a4a;
  padding: 20px 30px;
  transition: box-shadow 0.2s ease;
}

.ineligible-products__content:focus,
.ineligible-products__content:hover,
.eligible-products__content:focus,
.eligible-products__content:hover,
.active-contracts__toggle:focus,
.active-contracts__toggle:hover,
.expired-contracts__toggle:focus,
.expired-contracts__toggle:hover {
  outline: 0;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease;
  cursor: pointer;
}

.ineligible-products__content:focus,
.ineligible-products__content:hover {
  box-shadow: none;
}

.active-contracts__collapse,
.expired-contracts__collapse,
.eligible-products__collapse {
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.active-contract-details,
.expired-contract-details {
  position: relative;
}

.active-contract-details:last-child,
.expired-contract-details:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.active-contract-details-claim,
.expired-contract-details-claim {
  text-transform: capitalize;
}

.active-contract-details-claim--null,
.expired-contract-details-claim--null {
  color: #a2a2a2;
}

.active-contract-details-claim--inProcess,
.expired-contract-details-claim--inProcess {
  color: #000000;
}

.active-contract-details-claim--hiClydeHandoff,
.active-contract-details-claim--underClydeReview,
.active-contract-details-claim--underAdminReview,
.expired-contract-details-claim--hiClydeHandoff,
.expired-contract-details-claim--underClydeReview,
.expired-contract-details-claim--underAdminReview {
  color: #000000;
}

.active-contract-details-claim--needMoreInfo,
.expired-contract-details-claim--needMoreInfo {
  color: #ff4500;
}

.active-contract-details-claim--approved,
.expired-contract-details-claim--approved {
  color: #321798;
}

.active-contract-details-claim--resolutionInitiated,
.expired-contract-details-claim--resolutionInitiated,
.active-contract-details-claim--resolutionInProgress,
.expired-contract-details-claim--resolutionInProgress,
.active-contract-details-claim--resolutionOrdered,
.expired-contract-details-claim--resolutionOrdered,
.active-contract-details-claim--resolutionDelayed,
.expired-contract-details-claim--resolutionDelayed,
.active-contract-details-claim--resolutionFulfilled,
.expired-contract-details-claim--resolutionFulfilled {
  color: #05c379;
}

.active-contract-details-claim--customerWithdrawn,
.active-contract-details-claim--denied,
.expired-contract-details-claim--customerWithdrawn,
.expired-contract-details-claim--denied {
  color: #101010;
}

.active-contract-details-claim--open,
.expired-contract-details-claim--open {
  color: white;
}

.active-contract-details__toggle,
.expired-contract-details__toggle,
.eligible-products__collapse__content__row {
  border-top: 1px solid #eeeeee;
  padding: 8px 50px;
}

.active-contract-details__toggle,
.expired-contract-details__toggle {
  outline: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.5s ease, border 0.5s ease, border-radius 1s ease;
  overflow: hidden;
}

.active-contract-details__toggle__left,
.expired-contract-details__toggle__left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.active-contract-details__toggle__left h6,
.expired-contract-details__toggle__left h6 {
  color: #000000 !important;
  font-weight: bold;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
}

.expired-contract-details__toggle__left h6 {
  color: #5c5c5c;
}

.active-contract-details__toggle__right,
.expired-contract-details__toggle__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.active-contract-details__toggle__right div,
.expired-contract-details__toggle__right div {
  margin-right: 20px;
}

.active-contract-details__toggle--closed,
.expired-contract-details__toggle--closed {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.active-contract-details__menu,
.expired-contract-details__menu {
  width: 350px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 38px;
  right: 50px;
  z-index: 1000;
}
.active-contract-details__menu li,
.expired-contract-details__menu li {
  height: 50px;
  width: 100%;
}

.active-contract-details__menu__button,
.expired-contract-details__menu__button {
  height: 100%;
  width: 100%;
  font-size: 14px;
  padding: 0 20px;
}

.active-contract-details__menu__button:focus,
.active-contract-details__menu__button:hover,
.expired-contract-details__menu__button:focus,
.expired-contract-details__menu__button:hover {
  outline: 0;
  background-color: #f6f6f6;
  border-radius: 5px;
}

.active-contract-details__menu__button--disabled {
  color: red;
  pointer-events: none;
  width: 100%;
}

.active-contract-details__toggle--closed:focus,
.active-contract-details__toggle--closed:hover,
.expired-contract-details__toggle--closed:focus,
.expired-contract-details__toggle--closed:hover {
  outline: 0;
  background-color: #f6f6f6;
  cursor: pointer;
  overflow: hidden;
}

.active-contracts__collapse__content:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.active-contract-details__toggle--open {
  outline: 0;
  background-color: #000000;
  font-family: Volte Medium;
  color: white;
  border-top: 1px solid #000000;
}
.active-contract-details__toggle--open h6 {
  color: #ffffff !important;
}

.expired-contract-details__toggle--open {
  outline: 0;
  background-color: #5c5c5c;
  font-family: Volte Medium;
  color: white;
  border-top: 1px solid #5c5c5c;
}

.active-contract-details__toggle--open > div > .active-contract-details-claim,
.expired-contract-details__toggle--open > div > .expired-contract-details-claim {
  color: white;
}

.active-contract-details__toggle--open:hover {
  cursor: pointer;
}

.expired-contract-details__toggle--open:hover {
  background-color: #5c5c5c;
  cursor: pointer;
}

.active-contract-details__toggle.active-contract-details__toggle--open h6,
.expired-contract-details__toggle.expired-contract-details__toggle--open h6 {
  color: white;
}

.active-contract-details__toggle h6,
.eligible-products__collapse__content__row h6,
.active-contract-details__collapse__content h6,
.expired-contract-details__toggle h6,
.expired-contract-details__collapse__content h6 {
  display: inline-block;
  font-family: Volte Medium;
  font-size: 15px;
}

.expired-contract-details__toggle h6 {
  color: #5c5c5c;
}

.eligible-products__collapse__content__row {
  display: flex;
  align-items: center;
}
.eligible-products__collapse__content__row h6 {
  color: #000000;
  max-width: 50%;
  vertical-align: bottom;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.active-contract-details__collapse__content,
.expired-contract-details__collapse__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 50px;
}
.active-contract-details__collapse__content ul,
.expired-contract-details__collapse__content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.active-contract-details__collapse__content__expires,
.expired-contract-details__collapse__content__expires {
  width: 200px;
}
.active-contract-details__collapse__content__expires h6,
.expired-contract-details__collapse__content__expires h6 {
  font-family: Volte Medium Italic;
}

.active-contract-details__collapse__content__info,
.expired-contract-details__collapse__content__info {
  width: 250px;
}

.active-contract-details__collapse__content__sale,
.expired-contract-details__collapse__content__sale {
  width: 150px;
}

.active-contract-details__collapse__content__sale__serial-number,
.expired-contract-details__collapse__content__sale__serial-number {
  height: 30px !important;
  font-size: 12px !important;
  padding: 5px 10px !important;
}

.active-contract-details__collapse__content__claim,
.excpired-contract-details__collapse__content__claim {
  width: 200px;
}

.active-contracts__toggle__left,
.eligible-products__content__left,
.ineligible-products__content__left,
.expired-contracts__toggle__left {
  min-width: 460px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.active-contracts__toggle__left,
.eligible-products__content__left,
.ineligible-products__content__left,
.expired-contracts__toggle__left {
  width: 45%;
}

.active-contracts__toggle__left.oem,
.eligible-products__content__left.oem,
.ineligible-products__content__left.oem,
.expired-contracts__toggle__left.oem {
  width: 25%;
}

.active-contracts img,
.eligible-products img,
.ineligible-products__content__left img,
.expired-contracts__toggle__left img {
  max-height: 70px;
  width: 70px;
  margin-right: 15px;
}

.eligible-products__content__left__body,
.ineligible-products__content__left__body,
.active-contracts__toggle__left__content,
.expired-contracts__toggle__left__content {
  display: flex;
  flex-direction: column;
}

.cop-item-copy,
.active-contracts__toggle__left__name,
.eligible-products__content__left__name,
.ineligible-products__content__left__name,
.expired-contracts__toggle__left__name {
  font-family: Volte Medium;
}

.cop-item-copy--grey {
  color: #9a9a9a;
}

.eligible-products__content__right,
.ineligible-products__content__right,
.active-contracts__toggle__right,
.expired-contracts__toggle__right {
  min-width: 460px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.eligible-products__content__right,
.ineligible-products__content__right,
.active-contracts__toggle__right,
.expired-contracts__toggle__right {
  width: 45%;
}

.eligible-products__content__right.oem,
.ineligible-products__content__right.oem,
.active-contracts__toggle__right.oem,
.expired-contracts__toggle__right.oem {
  width: 65%;
}

.active-contracts__toggle__right__content,
.eligible-products__content__right__body,
.ineligible-products__content__right__body,
.expired-contracts__toggle__right__content {
  display: flex;
  flex-direction: column;
}

.active-contracts__toggle__right__content,
.eligible-products__content__right__body,
.ineligible-products__content__right__body,
.expired-contracts__toggle__right__content {
  width: 48%;
}

.active-contracts__toggle__right__content.oem,
.eligible-products__content__right__body.oem,
.ineligible-products__content__right__body.oem,
.expired-contracts__toggle__right__content.oem {
  width: calc(33.3333333333% - 5px);
}

.badge-container {
  align-items: center;
}

.limited-warranty-badge,
.limited-warranty-badge-contract {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 9px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.limited-warranty-badge {
  background: #e8f0fd;
}

.limited-warranty-badge-contract {
  color: #000000;
}

.limited-warranty-expiration,
.limited-warranty-expiration-contract {
  font-family: inherit;
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
}

.product-oem-warranty {
  position: relative;
  padding: 8px 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-oem-warranty {
  position: relative;
}

.product-oem-warranty__left {
  width: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product-oem-warranty__left h6 {
  display: inline-block;
  font-family: Volte Medium;
  font-size: 15px;
  color: #000000;
  max-width: 50%;
  vertical-align: bottom;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.product-oem-warranty__right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
}
.product-oem-warranty__right div {
  margin-right: 20px;
}

.eligible-products__content__right__body,
.ineligible-products__content__right__body {
  position: relative;
}

.all-products__delivery-date {
  transition: color 0.2s ease;
}

.all-products__delivery-date--active {
  color: #05c379;
  transition: color 0.2s ease;
}

.eligible-products__calendar,
.ineligible-products__calendar {
  position: absolute;
  transition: height 0.5s ease;
  top: 48px;
  right: 4px;
  z-index: 100;
  overflow: hidden;
}

.eligible-products__calendar--closed,
.ineligible-products__calendar--closed {
  height: 0px;
}

.eligible-products__calendar--open,
.ineligible-products__calendar--open {
  height: 300px;
  margin-bottom: 20px;
}

.eligible-products__calendar .rdr-Calendar,
.ineligible-products__calendar .rdr-Calendar {
  height: 300px;
  width: 300px !important;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: solid 1px black;
  cursor: default;
}

.eligible-products__calendar .rdr-MonthAndYear-innerWrapper,
.ineligible-products__calendar .rdr-MonthAndYear-innerWrapper {
  width: 280px;
}

.eligible-products__calendar .rdr-WeekDays,
.ineligible-products__calendar .rdr-WeekDays {
  width: 280px;
  display: flex;
  justify-content: space-between;
  cursor: default;
}

.eligible-products__calendar .rdr-Days,
.ineligible-products__calendar .rdr-Days {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.eligible-products__calendar .rdr-Day,
.ineligible-products__calendar .rdr-Day {
  border-radius: 5px;
}

.eligible-products__calendar .rdr-Day.is-selected,
.ineligible-products__calendar .rdr-Day.is-selected {
  background-color: #000000 !important;
}

.all-products__quantity-delivered {
  margin-left: 10px;
  height: 25px;
  width: 50px !important;
}

.auto-tooltip {
  color: #fff;
  background-color: #000000;
  border-radius: 5px;
  position: absolute;
  bottom: 103%;
  left: 0;
  padding: 8px 20px 6px 20px;
  font-size: 8px;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 400ms ease;
}

.auto-tooltip.show {
  visibility: visible;
  opacity: 1;
}

.auto-tooltip div {
  margin: 5px 0;
  font-size: 14px;
  z-index: 1000;
}

.auto-tooltip:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 5%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #000000;
  border-bottom: 0;
  margin-left: -5px;
  margin-bottom: -5px;
}

.auto-tooltip__confirm-text {
  cursor: pointer;
  font-weight: bold;
  text-align: right;
}

.profile-label {
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 0;
}

.profile-label--invalid {
  color: #f15a57;
}

.settings-tile__content__loading-container--profile {
  position: relative;
}

.settings-tile-content--profile {
  min-height: 340px;
}

.profile-settings-button-container {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 65px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.toggle-onlyRegistrable {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 49px;
  width: 64px;
  height: 32px;
  line-height: 20px;
  float: right;
  margin-bottom: 2px;
  border: solid 1px #d1d1d1;
}

.toggle-onlyRegistrable--on {
  background: #000000;
}

.toggle-onlyRegistrable--off {
  background: #f6f6f6;
}

.toggle-onlyRegistrable__dot {
  background: #ffffff;
  width: 28px;
  height: 28px;
  border-radius: 49px;
  position: absolute;
  top: 1px;
  border: 0.5px solid rgba(0, 0, 0, 0);
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.0784313725), 0px 3px 8px 0px rgba(0, 0, 0, 0.1490196078);
}

.registrable-product-switch {
  display: flex;
  align-items: center;
}

.registrable-product-switch-title {
  margin-right: 16px;
}

.clyde-modal-content--resend-email {
  height: 100%;
  top: 20%;
}

.resend-modal-content {
  top: 20%;
  min-height: 300px;
  width: 550px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: left;
  padding: 50px 60px 20px 50px;
}

.resend-contact-explanation {
  margin-top: 15px;
  text-align: left;
}

.resend-contact {
  margin: 30px auto;
  text-align: left;
}

.resend-contact__label {
  display: inline-block;
}

.resend-contact__label--invalid {
  color: #f15a57;
}

.resend-contact__input {
  display: inline-block;
}

.cancel-resend {
  cursor: pointer;
  float: right;
  color: #909090;
  font-size: 30px;
  line-height: 30px;
}

.resend-actions {
  text-align: center;
  height: 50px;
  width: 100%;
  margin: auto;
}

.resend-actions .resend-actions__confirm {
  width: 200px;
  box-shadow: none;
  font-weight: normal;
  display: inline-block;
  margin: 0 5px;
}

.resend-modal-content--action-complete {
  padding-top: 80px;
}

.resend-loading-container {
  margin-top: 80px;
}

.sale-modal {
  position: absolute;
  width: 100%;
  top: 5%;
  height: 95%;
}

.sale-modal__content,
.sale-modal__missing-billing {
  margin: auto;
  text-align: left;
  width: 75%;
  padding: 50px 60px;
  font-size: 22px;
  letter-spacing: -0.2px;
  overflow: scroll;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 100%;
  transition: height ease 300ms;
}

.sale-modal--finished {
  height: 80%;
}

.close-sale-modal {
  cursor: pointer;
  float: right;
  color: #949494;
  font-size: 30px;
  line-height: 30px;
}

.update-billing-modal {
  position: absolute;
  width: 100%;
  top: 5%;
  height: 300px;
}

.update-billing-modal__content {
  font-size: 18px;
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  width: 50%;
  margin: auto;
  margin-top: 140px;
  text-align: center;
  padding: 50px;
}

.update-billing-modal__content__button {
  text-align: center;
  width: 100%;
  border-radius: 5px;
  background: #000000;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 50px;
  cursor: pointer;
  margin-top: 8px;
  margin-bottom: 8px;
  transition: box-shadow 200ms ease;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
}

.update-billing-modal__content__button:active {
  box-shadow: none;
}

.sale-modal-step {
  margin-top: 20px;
  font-size: 16px;
  margin-left: 3px;
}

.sale-modal__customer-cost label {
  justify-content: space-between;
  width: 100%;
  margin-inline: 0;
}
.sale-modal__customer-cost .clyde-tooltip--indicator {
  max-height: 20px;
}

.sales-list-container {
  width: 100%;
  font-size: 14px;
}

.sales-list-container__header {
  line-height: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 3px 3px 0 0;
  text-align: center;
  margin: 0;
  padding: 0;
}

.sales-list-container__header div {
  user-select: none;
  border-right: 1px solid #d5d5d5;
  border-bottom: 1px solid #d5d5d5;
}

.sales-list-container__header div:last-child {
  border-right: none;
}

.contract-sales-title-container {
  align-items: end;
}

.sale-pagination-row--bottom {
  margin-bottom: 40px;
  padding-top: 20px;
}

.sale-pagination-row ul {
  float: right;
}

.sales-search-form__hidden-input {
  display: none;
}

.button {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
  background: #414141;
}

.button--hidden {
  width: 0px;
  opacity: 0;
  cursor: default;
  letter-spacing: -8px;
}

.button--disabled {
  background-color: #414141;
  cursor: default;
  box-shadow: none;
}

.button--disabled:hover,
.button--disabled:focus {
  background-color: #414141;
}

.button span.button-text {
  transition: all 100ms ease;
}

.select-contract-tile {
  padding: 10px 20px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 5px;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  text-align: center;
  transition: all 200ms ease;
}

.select-contract-tile:focus,
.select-contract-tile:hover,
.select-contract-tile--active {
  background-color: #000000;
  color: #ffffff;
}

.select-contract-info {
  margin-top: 8px;
  font-size: 14px;
}

.select-contract-info__category-link {
  text-decoration: underline;
  transition: color 100ms ease;
  cursor: pointer;
}

.select-contract-info__category-link:hover {
  color: #e07400;
}

.select-variant-tile {
  padding: 8px 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  margin-left: 15px;
  margin-top: 5px;
  cursor: pointer;
  color: #000000;
  font-size: 16px;
  text-align: center;
  transition: all 200ms ease;
}

.select-variant-tile:hover,
.select-variant-tile--active {
  background-color: #000000;
  color: #ffffff;
}

.autopilot-switch {
  cursor: pointer;
  position: relative;
  border-radius: 100px;
  width: 100px;
  height: 50px;
  line-height: 38px;
}

.autopilot-switch--on {
  background: #000000;
}

.autopilot-switch--off {
  background: #909090;
}

.autopilot-switch__contents {
  z-index: 10;
  background: #fbfbfb;
  width: 38px;
  height: 38px;
  border-radius: 18px;
  position: absolute;
  margin: 6px;
}

.autopilot-switch__text {
  z-index: 11;
  line-height: 52px;
  margin-left: 16px;
  margin-right: 16px;
}

.autopilot-switch__text__on {
  float: left;
  color: #fbfbfb;
  font-family: "Volte";
  font-size: 14px;
}

.autopilot-switch__text__off {
  float: right;
  color: #fbfbfb;
  font-family: "Volte";
  font-size: 14px;
}

.settings-tile-container {
  margin-bottom: 38px;
}

.settings-save-button {
  float: right;
}

.small-switch {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 100px;
  width: 52px;
  height: 22px;
  float: right;
  transition: all 250ms ease;
}

.small-switch--true {
  background: #000000;
  text-align: right;
}

.small-switch--false {
  background: #05c379;
}

.small-switch--disabled {
  background: #909090;
  cursor: not-allowed;
}

.small-switch--partial {
  background: #414141;
}

.small-switch__dot__text {
  color: #ffffff;
  font-family: "Volte";
  font-size: 12px;
}

.small-switch__dot__text--true {
  position: absolute;
  left: -28px;
  top: -1px;
}

.small-switch__dot__text--false {
  position: absolute;
  left: 17px;
  top: -1px;
}

.small-switch__dot {
  background: #f3f3f3;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 4.3px;
}

.spinner {
  text-align: center;
}

.spinner > div {
  width: 10px;
  height: 10px;
  margin: 0 4px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.spinner.page-loading div {
  width: 30px;
  height: 30px;
  margin: 0 10px;
}

.terms-container {
  margin-top: 25px;
  width: 100%;
  background: #ffffff;
  border: 2px solid #d7d7d7;
  border-radius: 5px;
  padding: 60px;
  font-size: 14px;
  text-align: center;
}

.terms-container__logo {
  margin-bottom: 40px;
}

.terms-container__text {
  text-align: left;
}

.terms-agree-row {
  align-items: center;
}

.terms-agree {
  display: flex;
}

.terms-agree__text {
  cursor: pointer;
  line-height: 32px;
  margin: 0 10px;
}

.terms-accept-container {
  text-align: right;
}

.terms-accept-button {
  display: none;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  float: right;
  background: #000000;
  border-radius: 5px;
  width: 147px;
  line-height: 44px;
  text-align: center;
  color: #ffffff;
  user-select: none;
  box-shadow: none;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
}
.terms-accept-button:active {
  box-shadow: 0px 0px 0px 0px #fff;
  outline: none;
}
.terms-accept-button:hover {
  box-shadow: -2px 2px 8px 0px #b3b3b3;
  background: #414141;
}

.terms-accept-button--show {
  display: block;
}

.actions-container {
  position: fixed;
  bottom: 0;
  user-select: none;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-left: 3%;
}
@media (min-width: 1440px) {
  .actions-container {
    margin-left: 2.5%;
  }
}
@media (max-width: 1000px) {
  .actions-container {
    margin-left: 1%;
  }
}

.actions-container--product {
  margin-left: 22px;
  margin-bottom: 22px;
}

.actions-container__button {
  display: inline-block;
  margin: 8px;
  background-color: #000000;
  border-radius: 50%;
  height: 54px;
  width: 54px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
}
.actions-container__button:hover {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  background: #414141;
}
.actions-container__button:active {
  box-shadow: none;
}

.actions-container__button__image {
  width: 64%;
  height: 50%;
  position: relative;
  top: 25%;
}

[src="/images/download.svg"] {
  width: 35%;
  height: 35%;
}

[src="/images/Sales.svg"],
[src="/images/upload.svg"],
[src="/images/new-contracts.svg"] {
  width: 40%;
  height: 40%;
}

[src="/images/new-contracts.svg"] {
  margin-top: 3px;
}

.actions-container__button__image--sales-import {
  top: 28%;
}

.actions-container__button__plus {
  color: white;
  position: absolute;
  font-weight: 700;
  font-size: 20px;
  right: 20px;
  top: 8px;
}

.actions-container__tooltip__text {
  min-width: 140px;
}

.settings-tile--user-management {
  margin-bottom: 55px;
}

.user-management__user-item {
  margin-bottom: 25px;
}

.user-management__user-item:last-child {
  margin-bottom: 0px;
}

.user-management__user-item-label {
  margin-top: 7px;
  margin-bottom: 2px;
}

.user-management__email,
.user-management__password {
  font-size: 14px;
  margin-bottom: 0;
}

.user-management__last-login-time {
  font-size: 14px;
}

.user-management__account-type-select {
  margin-bottom: 5px;
}

.user-management__reset-pw-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 12px;
}

.user-management__reset_pw_button--normal {
  background-color: #f15a57;
}

.user-management__reset_pw_button--reset {
  background-color: #000000;
}

.user-management__reset_pw_button--disabled {
  background-color: #909090;
  cursor: unset;
}

.user-management__reset_pw_button--disabled:hover {
  box-shadow: none;
}

.create-user__tile {
  margin-bottom: 25px;
}

.create-user__feedback-errors-list {
  color: red;
  list-style: none;
  margin: 20px 0 0 0;
  padding: 0;
}

.create-user__feedback-success {
  margin-top: 20px;
  color: green;
}

@media screen and (min-width: 768px) {
  .create-user__feedback-errors-list,
  .create-user__feedback-success {
    margin: 0;
  }
}
@media screen and (min-width: 992px) {
  .user-management__user-item-content {
    display: flex;
    align-items: center;
  }
  .user-management__user-item-content--with-pw {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .user-management__column-labels {
    margin-bottom: 10px;
  }
  .user-management__user-item {
    margin-bottom: 20px;
  }
  .user-management__user-item-label {
    display: none;
    margin: 0;
  }
  .user-management__account-type-select {
    margin: 0;
  }
  .user-management__reset-pw-section {
    margin: 0;
    margin-left: -20px;
  }
  .user-management__reset_pw_button--normal,
  .user-management__reset_pw_button--reset {
    font-size: 14px;
    line-height: 20px;
    white-space: normal;
    min-height: 40px;
    max-height: 50px;
  }
}
.settings-tile__title__updated {
  color: #000000;
  font-size: 14px;
  margin-left: 20px;
}

.settings-tile__title__oauth-disabled-message {
  font-size: 12px;
  line-height: 32px;
  color: #949494;
  float: right;
}

.account-label {
  font-size: 14px;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 0;
}

.account-label--invalid,
.invalid-password {
  color: #f15a57;
}

.account-label--inline-block {
  display: inline-block;
}

div.password-strength {
  display: inline-block;
}

div.password-strength span {
  display: inline-block;
  padding: 2px 10px;
  border-radius: 4px;
  min-width: 20px;
  margin: 3px 1px;
  border: none;
  transition: all 300ms ease;
}

div.password-strength span {
  background-color: #f3f3f3;
}

div.password-strength.strength-1 span.strength-1,
div.password-strength.strength-2 span.strength-1,
div.password-strength.strength-3 span.strength-1,
div.password-strength.strength-4 span.strength-1,
div.password-strength.strength-5 span.strength-1 {
  background-color: #eb5f5d;
}

div.password-strength.strength-2 span.strength-2,
div.password-strength.strength-3 span.strength-2,
div.password-strength.strength-4 span.strength-2,
div.password-strength.strength-5 span.strength-2 {
  background-color: #da716d;
}

div.password-strength.strength-3 span.strength-3,
div.password-strength.strength-4 span.strength-3,
div.password-strength.strength-5 span.strength-3 {
  background-color: #fdec4e;
}

div.password-strength.strength-4 span.strength-4 {
  background-color: #000000;
}

.user-settings-save-container {
  width: 100%;
  margin-bottom: 65px;
  margin: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.user-type-switch {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 100px;
  width: 82px;
  height: 22px;
  float: left;
  transition: all 250ms ease;
  margin-top: 5px;
}

.user-type-switch--false {
  background: #000000;
  text-align: right;
}

.user-type-switch--true {
  background: #909090;
}

.user-type-switch--admin {
  background: #000000;
  text-align: right;
}

.user-type-switch--basic {
  background: #05c379;
}

.user-type-switch--partial {
  background: #414141;
}

.user-type-switch--disabled {
  background: #909090;
  cursor: unset;
}

.user-type-switch__dot__text {
  color: #ffffff;
  font-family: "Volte";
  font-size: 12px;
}

.user-type-switch__dot__text--false {
  position: absolute;
  right: 20px;
  top: -1px;
}

.user-type-switch__dot__text--true {
  position: absolute;
  left: 18px;
  top: -1px;
}

.user-type-switch__dot {
  background: #f3f3f3;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  top: 4.3px;
}

@media screen and (min-width: 992px) {
  .user-type-switch {
    float: right;
  }
  .user-type-switch {
    margin: 0;
  }
}
.loading-backdrop--clear {
  background-color: transparent;
}

.toggle-variant {
  cursor: pointer;
  position: relative;
  user-select: none;
  overflow-x: hidden;
  border-radius: 100px;
  width: 35px;
  height: 20px;
  line-height: 20px;
  float: right;
  margin-bottom: 2px;
}

.toggle-variant--on {
  background: #000000;
}

.toggle-variant--off {
  background: #909090;
}

.toggle-variant__dot {
  background: #fbfbfb;
  width: 14px;
  height: 14px;
  border-radius: 100px;
  position: absolute;
  top: 3px;
}

.waw-settings__header-section {
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
}

.waw-settings__header-section-description h2 {
  line-height: 150%;
  font-weight: 600;
  font-size: 24px;
}
.waw-settings__header-section-description p {
  line-height: 150%;
}

.waw-settings__body_serial-number-section {
  padding: 26px 0;
  border-top: 2px solid #EEEEEE;
  border-bottom: 2px solid #EEEEEE;
}

.waw-settings__body_serial-number-toggle {
  display: flex;
  justify-content: space-between;
}
.waw-settings__body_serial-number-toggle h3 {
  font-size: 16px;
}
.waw-settings__body_serial-number-toggle p::before {
  content: "";
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  top: -4px;
  border-left: 1px solid #949494;
  border-bottom: 1px solid #949494;
  margin-right: 16px;
}

.waw-settings__body_serial-number-toggle-optional {
  padding-right: 14px;
}

.waw-settings__header-section-registrable-products-button {
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 16px;
  width: 283px;
  height: 48px;
}
.waw-settings__header-section-registrable-products-button::before {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 2C16.2626 1.73735 16.5744 1.52901 16.9176 1.38687C17.2608 1.24473 17.6286 1.17157 18 1.17157C18.3714 1.17157 18.7392 1.24473 19.0824 1.38687C19.4256 1.52901 19.7374 1.73735 20 2C20.2626 2.26264 20.471 2.57444 20.6131 2.9176C20.7553 3.26077 20.8284 3.62856 20.8284 4C20.8284 4.37143 20.7553 4.73923 20.6131 5.08239C20.471 5.42555 20.2626 5.73735 20 6L6.5 19.5L1 21L2.5 15.5L16 2Z' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.waw-settings__header-section-registrable-products-button:hover, .waw-settings__header-section-registrable-products-button:focus, .waw-settings__header-section-registrable-products-button:active {
  outline: none;
  background-color: #EEEEEE;
}

a.waw-settings__header-section-registrable-products-button {
  font-weight: 600;
  text-decoration: none;
  color: #000;
}

.actions-container.waw-settings {
  margin-left: 0;
  left: calc(3% + 50px);
}
@media (min-width: 1440px) {
  .actions-container.waw-settings {
    left: calc(2.5% + 50px);
  }
}
@media (max-width: 1000px) {
  .actions-container.waw-settings {
    left: calc(1% + 50px);
  }
}
