@import '../variables.scss';

$claimTableBorder: #D1D1D1;
$claimTableRed: #D52424;
$claimTableYellow: #F9D505;

.claims-summary {
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 50px;
  padding: 50px 10%;
  overflow: scroll;
}

.claims-summary__table {
  width: 100%;
  padding: 0;
}

.claims-summary__table__body {
  background: $clydeWhite;
  border: 1px solid $claimTableBorder;
  border-radius: 8px;

  tr {
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: $backgroundGrey;
    }
  }
}

.claims-summary__table tr {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid $claimTableBorder;
}

.claims-summary__table tr:last-of-type {
  border-bottom: none;
}

.claims-summary__table th,
.claims-summary__table td {
  width: 20%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 13px 16px;
}

.claims-summary__table th {
  font-family: 'Volte Bold';
  padding: 13px 16px;
}

.claim-summary__table__dot {
  display: inline-block;
  height: 9px;
  width: 9px;
  flex-shrink: 0;
  padding: 0;
  margin-right: 10px;
  border-radius: 50%;
  background-color: transparent;
}

.claims-summary__table__row--new {
  .claim-summary__table__dot {
    background: $claimTableRed;
  }

  td:nth-of-type(5) {
    color: $claimTableRed;
  }
}

.claims-summary__table__row--pending {
  .claim-summary__table__dot {
    background: $claimTableYellow;
  }
}

tr.claims-summary__table__row--empty {
  padding: 10px;
  border: 0;
  color: $inactiveGrey;
  cursor: default;
}

.claims-summary__table-info {
  margin: 10px 0 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.claims-summary__table-info .pagination {
  margin-top: 25px;
  margin-bottom: 0;
  margin-left: auto;
}

.claims-summary__table-info__scopes {
  display: flex;

  h4 {
    font-family: 'Volte Bold';
    font-size: 16px;
    margin: 5px 0;
  }
}

.claims-summary__scopes__search,
.claims-summary__scopes__filter {
  margin-right: 10px;
}

.claims-summary__scopes__search .clyde-select,
.claims-summary__scopes__filter .clyde-select,
.claims-summary__scopes__sort .clyde-select {
  height: 38px;
  border-radius: 8px;
}

.claims-summary__scopes__search__input {
  border-radius: 8px;
}

.claims-summary__scopes__search {
  min-width: 90px;
}
.claims-summary .clyde-select-single__options-list {
  width: auto;
  top: 0;
  left: 0;
  min-width: 100%;
  border-radius: 8px;
  white-space: nowrap;
  z-index: 10;
  padding-left: 0;
}
