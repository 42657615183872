$background: #f6f6f4;

$clydeBase: #000000;
$clydeBaseHover: #414141;
$clydeSecondary: #e07400;

$clydeWhite: #ffffff;
$clydeGreen: #05c379;

$textGrey: #949494;

$borderGrey: #d1d1d1;

$backgroundGrey: #f3f3f3;
$buttonBorderGrey: #d9d9d9;
$buttonHoverGrey: #d7d7d7;
$backgroundWhite: #ffffff;

$inactiveRed: #f15a57;
$inactiveGrey: #909090;

$mediumGrey: #727272;
$alertRed: #d52424;
